@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

body {
	color: var(--oslo-gray);
	font-family: 'Nunito Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

.wrapper {
	display: flex;
	min-height: 100vh;
	background-color: #f5f5f5;
}

.content-area {
	padding: 16px 0;
	min-height: calc(100vh - 64px);
	div {
		.landing-background {
			min-height: calc(100vh + 134px);
		}
	}
}

.divider {
	margin: 16px 0;
	border: 1px solid #ebebeb;
}

.page-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 16px;

	.page-heading {
		font-size: 30px;
		line-height: 30px;
		font-weight: 700;
		margin: 0;
		margin-right: 15px;
	}

	.page-heading-manage-reward {
		font-size: 24px;
		line-height: 30px;
		font-weight: 700;
		margin: 0;
		margin-right: 15px;
	}

	.info-action {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;

		> li {
			font-size: 14px;
			line-height: 18px;
			display: flex;

			.icon-link {
				font-size: 20px;
				cursor: pointer;
				color: var(--theme-primary);
			}

			&:not(:last-child) {
				margin-right: 8px;
			}
		}
	}

	.custom-back-btn {
		margin: 0;
	}
}

.sub-title {
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	margin: 0 0 24px;
}

.custom-card {
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 16px;
	padding: 16px 24px;
	margin-bottom: 20px;

	.custom-card-head {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 8px;

		.custom-card-heading {
			font-size: 20px;
			line-height: 26px;
			font-weight: 700;
			margin-bottom: 8px;
			margin-right: 10px;
		}
	}
}

.DraftEditor-editorContainer {
	font-family: 'Nunito Sans', sans-serif;
}

.tag-wrap {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;

	.tag {
		background-color: #6e86b3;
		color: #fff;
		padding: 5px 16px 4px;
		border-radius: 29px;
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 5px;
		}

		.tag-name {
			font-size: 14px;
			line-height: 19px;
		}

		.tag-close {
			font-size: 8px;
			margin-left: 8px;
			display: block;
		}

		&.green {
			background-color: var(--fern);
		}
	}
}

.label-optional {
	color: #999;
	font-style: italic;
	font-size: 12px;
}

.click-to-upload {
	position: relative;
	overflow: hidden;
	line-height: 1;

	label {
		cursor: pointer;
		color: var(--oslo-gray);
		line-height: 1;
		margin: 0;

		.link {
			color: var(--theme-primary);
			font-weight: 700;
		}
	}
	input[type='file'] {
		opacity: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
	}
}

.custom-input-file {
	position: relative;
	overflow: hidden;

	label {
		cursor: pointer;
		color: var(--theme-primary) !important;
	}

	input[type='file'] {
		opacity: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
	}
}

.custom-form-group {
	margin: 0 0 20px;
	&.error {
		.custom-input {
			border-color: #f5222d;
		}
	}
	.custom-form-green-select {
		width: 100%;
		.ant-select-selection-item {
			user-select: none;
			background-color: #6aa688;
			color: #fff;
			padding: 6px 16px;
			border-radius: 30px;
			display: flex;
			align-items: center;
			margin: 4px;
			height: auto;
			.ant-select-selection-item-content {
				font-size: 12px;
				line-height: 16px;
				padding-right: 12px;
			}
			.ant-select-selection-item-remove {
				color: #fff;
			}
		}
	}
	.custom-input {
		height: auto;
		min-height: 40px;
		line-height: 1;
		padding: 11px 16px;
		border: 1px solid #d6d6d6;
		border-radius: 8px;
		width: 100%;
		font-size: 14px;
		font-family: 'Open Sans', sans-serif;
		color: #7f7f7f;

		&.override-selector-height {
			.ant-select-selector {
				height: 40px;
			}
		}

		&.text-blue {
			color: var(--theme-primary);
		}

		&.text-green {
			color: #47b549;
		}

		&:disabled {
			background-color: #fafafa;
			cursor: no-drop;
		}

		&.ant-input-number {
			padding: 0;

			.ant-input-number-input {
				height: auto;
				min-height: 40px;
				padding: 11px 16px;
				font-size: 14px;
			}
		}

		&.ant-select {
			border: 0;
			padding: 0;
		}
		.ant-select-selector {
			height: auto;
			border-radius: 8px;
			min-height: 40px;
			padding-bottom: 0;

			.ant-select-selection-placeholder {
				align-content: center;
			}
			.ant-select-selection-item {
				align-content: center;

				> ul {
					> li {
						&.ant-select-selection__choice {
							user-select: none;
							background-color: #6aa688;
							color: #fff;
							padding: 6px 16px;
							border-radius: 30px;
							display: flex;
							align-items: center;
							margin: 4px;
							height: auto;
						}

						&.ant-select-search--inline {
							height: 30px;
							line-height: 26px;
						}
					}
				}
			}

			&__choice {
				&__content {
					font-size: 12px;
					line-height: 16px;
					padding-right: 12px;
				}

				&__remove {
					color: #fff;
					margin-top: -4px;
				}
			}

			.ant-select-arrow {
				right: 8px;
				top: 48%;
			}
		}

		&.ant-calendar-picker {
			padding: 0;
			border: 0;

			.ant-calendar-picker-input {
				padding: 11px 16px;
				height: auto;
				border-radius: 8px;
				border: 1px solid #d6d6d6;
				line-height: 16px;
				color: #7f7f7f;
				font-size: 12px;

				i {
					margin-top: -8px;
				}
			}
		}

		&.secondary {
			.ant-select-selection {
				&__rendered {
					ul {
						li {
							background-color: #6e86b3;
						}
					}
				}
			}
		}

		&.ant-input-password {
			padding: 0;
			.ant-input {
				height: auto;
				padding: 11px 32px 11px 16px;
				line-height: 1;
				border-radius: 8px;
			}
		}

		&.custom-input-autocomplete {
			border: 1px solid #d6d6d6;

			&:focus {
				box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
			}

			.ant-input {
				border: 0;
				padding: 0;

				&:focus {
					box-shadow: none;
				}
			}

			.ant-select-selection__placeholder {
				margin-right: 0;
				margin-left: 0;
			}

			.ant-select-selection__rendered {
				> ul {
					> li {
						&.ant-select-search--inline {
							width: 100%;
							height: auto;
							margin: 0;
							padding: 0;
							background: transparent;
						}
					}
				}
			}
		}
	}

	.cf-addon-wrap {
		display: flex;

		.custom-input {
			border-radius: 8px 0 0 8px;

			&:focus {
				& + .cf-addon {
					box-shadow: 0 0 0 2px rgb(24, 144, 255, 0.2);
				}
			}
		}

		.cf-addon {
			border: 1px solid #d6d6d6;
			border-left: 0;
			border-radius: 0 8px 8px 0;
			line-height: 40px;
			padding: 0 9px;
			font-size: 12px;
			font-weight: 400;
			color: #000;
			transition: all 0.3s linear;
		}

		.ant-select-auto-complete {
			&.ant-select-lg {
				.ant-input {
					height: auto;
					padding-top: 0;
					padding-bottom: 0;
					line-height: 40px;
					border-radius: 8px 0 0 8px;
				}
			}
		}
	}

	.custom-label {
		font-size: 14px;
		line-height: 15px;
		// color: var(--oslo-gray);
		color: black;
		margin-bottom: 8px;
		display: block;

		span {
			color: #999;
			font-style: italic;
			font-size: 12px;

			&.sub-label {
				font-style: normal;
				padding-left: 8px;
			}
		}
	}

	&.custom-checkbox {
		input {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked {
				& + label {
					color: var(--theme-primary);

					&:after {
						content: '\e905';
						font-family: 'icomoon';
						display: block;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						background: var(--theme-primary);
						width: 20px;
						height: 20px;
						font-size: 10px;
						line-height: 20px;
						text-align: center;
						color: #fff;
					}
				}
			}

			&:disabled {
				& + label {
					cursor: no-drop;
					color: #999;

					&:after {
						background: #999;
					}
				}
			}
		}

		label {
			position: relative;
			cursor: pointer;
			white-space: normal;
			padding-right: 30px;

			&:before {
				content: '';
				-webkit-appearance: none;
				appearance: none;
				background-color: #fff;
				border: 1px solid #bfbfbf;
				padding: 9px;
				position: absolute;
				cursor: pointer;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&.left-check {
			label {
				padding-left: 30px;
				padding-right: 0;

				&::before,
				&::after {
					right: auto;
					left: 0;
				}
			}
		}
	}

	.input-error,
	.ant-form-explain {
		color: #f5222d;
		font-size: 12px;
		line-height: 14px;
		font-weight: 600;
		margin: 0;
		min-height: auto;
	}

	.ant-form-item-children {
		display: flex;
	}

	.ant-form-item {
		margin: 0;
	}
	.ant-legacy-form-item {
		margin: 0;
		&.ant-row {
			width: 100%;
		}
	}
	.custom-input-dropdown {
		position: absolute;
		border-radius: 4px;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
		position: absolute;
		overflow: auto;
		max-height: 140px;
		margin-top: 2px;
		background: #fff;
		color: #999;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		width: 100%;
		z-index: 1;

		&-text {
			padding: 6px 12px;
		}

		&-item {
			padding: 6px 12px;
			color: #222;
			font-size: 13px;
			line-height: 18px;
			cursor: pointer;

			&.hovered {
				background-color: #e6f7ff;
			}
		}
	}
}

.custom-pagination-table .ant-pagination-simple .ant-pagination-simple-pager input {
	pointer-events: none;
	border: none;
	background-color: transparent;
	text-align: center;
	color: inherit;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}



.table-card {
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 16px;
	padding: 0 22px;
	margin-bottom: 20px;
	overflow-y: auto;

	.ant-table-thead {
		> tr {
			> th {
				font-size: 14px;
				line-height: 16px;
				font-weight: 700;
				background-color: #ffffff;
				border-bottom: 1px solid #e2e2e2;
				word-break: normal;
				overflow-wrap: normal;
				padding: 16px 12px;
				background: transparent;
				content: '';

				.ant-table-column-sorter {
					vertical-align: unset;
				}
			}
		}
	}

	.ant-table-tbody {
		> tr {
			> td {
				color: #000;
				font-size: 14px;
				line-height: 16px;
				border-bottom: 0;
				padding: 12px;

				a {
					font-size: 14px;
					line-height: 16px;

					&.table-link {
						font-weight: 600;
					}
				}

				.table-status {
					color: #8e9aa3;
					font-weight: 700;
				}
			}
			&:hover {
				box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
				position: relative;
				z-index: 2;
				> td {
					background-color: #fff !important;
				}
			}
		}
	}

	.ant-table-pagination {
		float: none;
		text-align: center;
		justify-content: center;
	}
	.custom-image-wrap,
	.custom-avatar-wrap {
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 16px;
		border-radius: 12px;
	}
}

.table-card.table-modal {
	width: 66vw;
	width: 66dvw;
}

.search-input {
	position: relative;
	max-width: 408px;
	width: 100%;
	margin-right: 12px;

	input[type='text'] {
		height: auto;
		padding: 9px 38px 9px 14px !important;
		font-size: 14px;
		line-height: 16px;
		border: 1px solid #dddddd;

		&:focus {
			box-shadow: 2px 4px 19px rgba(0, 0, 0, 0.16);
		}
	}

	.search-icon {
		position: absolute;
		border-color: var(--bright-turquoise);
		background-color: var(--bright-turquoise);
		width: 36px;
		height: 100%;
		right: 0px;
		top: 0px;
		border-radius: 0 4px 4px 0;
		text-align: center;
		line-height: 18px;
		color: #fff;
		font-size: 18px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.custom-loader {
		position: absolute;

		i {
			font-size: 20px;
		}
	}
}

.custom-filter {
	margin: 0 0 10px;

	.filter-btn {
		background-color: #fff;
		flex: 0 0 auto;
		width: 36px;
		height: 36px;
		border: 1px solid #dddddd;
		border-radius: 5px;
		margin-right: 10px;
		margin-bottom: 10px;
		cursor: pointer;
		text-align: center;
		line-height: 28px;
		font-size: 20px;
	}
}

.filter-wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	> * {
		margin-bottom: 10px;
	}
	.ant-select-multiple {
		min-width: 178px;
		font-size: 14px;
		color: #000;
		margin-right: 12px;
		.ant-select-selector {
			height: 36px;
			border-radius: 18px;
			padding-bottom: 0;
			.ant-select-selection-placeholder {
				color: #000;
			}
			.ant-select-selection-item {
				border-radius: 24px;
			}
		}
	}
	.ant-select-single {
		min-width: 178px;
		font-size: 14px;
		color: #000;
		margin-right: 12px;

		.ant-select-selector {
			height: 36px;
			border-radius: 18px;
			padding-bottom: 0;

			&__rendered {
				line-height: 34px;
			}
			.ant-select-selection-placeholder {
				color: #000;
				line-height: 36px;
			}
			.ant-select-selection-item {
				border-radius: 24px;
				line-height: 36px;
			}

			&--multiple {
				.ant-select-arrow {
					top: 18px;
				}

				.ant-select-selection {
					&__rendered {
						margin-bottom: 0;

						> ul {
							> li {
								margin-top: 5px;
								border-radius: 24px;
							}
						}
					}

					&__choice {
						&__remove {
							top: 50%;
							transform: translateY(-50%);
							line-height: 0;
						}
					}
				}
			}
		}
	}
	.ant-picker {
		height: 36px;
		border-radius: 18px;
		font-size: 14px;
		color: #000;
		line-height: 24px;
		.ant-picker-range {
			height: auto;
			border-radius: 18px;
			font-size: 14px;
			color: #000;
			line-height: 24px;

			.ant-picker-input {
				height: auto;
			}
		}
	}

	.switch-container {
		margin-right: 12px;
	}

	.close-btn {
		min-width: 178px;

		.react-ripples {
			margin-bottom: 10px;
			width: 100%;
		}

		button {
			background-color: #fff;
			color: #000;
			height: auto;
			font-size: 14px;
			border-radius: 18px;
			line-height: 34px;
			width: 100%;
		}
	}
}

.switch-container {
	display: flex;
	align-items: center;

	.switch-label {
		font-size: 14px;
		color: #000;
		opacity: 0.5;

		&.left {
			margin-right: 10px;
		}

		&.right {
			margin-left: 10px;
		}

		&.active {
			font-weight: 700;
			opacity: 1;
		}
	}
}

.switch-wrap {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: 44px;
	height: 22px;
	border-radius: 11px;
	margin: 0;

	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + .switch-slider {
			background-color: var(--deep-blush);

			&:before {
				background: white;
				transform: translateX(23px);
			}
		}
	}

	.switch-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--deep-blush);
		-webkit-transition: 0.3s;
		transition: 0.3s;
		border-radius: 11px;

		&:before:hover {
			background-color: white;
		}

		&:before {
			position: absolute;
			content: '';
			height: 16px;
			width: 16px;
			left: 3px;
			bottom: 3px;
			background-color: #fff;
			transition: 0.3s;
			border-radius: 50%;
		}
	}

	&.single {
		.switch-slider {
			background-color: var(--oslo-gray);
		}
	}
}
.ant-picker-active-bar {
	display: none;
}
.ant-picker-320w-br {
	width: 100%;
	max-width: 320px;
	min-width: 220px;
}
.ant-picker-100w,
.ant-picker-480px-mw-no-bg,
.ant-picker-480px-mw {
	width: 100%;
	height: 40px;
	border-radius: 8px;
}
.ant-picker-480px-mw {
	max-width: 480px;
	border-color: #c9c9c9;
	background-color: #fafdff;
}
.ant-picker-480px-mw-no-bg {
	max-width: 480px;
}
.ant-picker-range-separator {
	font-size: 16px;
	color: rgb(0, 0, 0, 25%);
}
.bs-form-label {
	.aant-row .ant-legacy-form-item {
		width: 100%;
	}
}
.color-card-grid {
	display: grid;
	column-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	width: 100%;
	justify-content: center;

	.color-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #3d5a80;
		border-radius: 6px;
		padding: 10px;
		margin-bottom: 10px;
		text-align: center;
		min-width: 150px;
		height: 90px;
		perspective: 1000px;
		position: relative;
		transform-style: preserve-3d;
		transition: all 0.75s ease;
		z-index: 1;

		&.flipped {
			transform: rotateY(180deg);
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.13);
			z-index: 2;
		}

		.color-card-inner {
			width: 100%;
			height: 100%;
			transform-style: preserve-3d;
			display: flex;
			align-items: center;
		}

		.color-card-front,
		.color-card-back {
			width: 100%;
			height: fit-content;
			position: absolute;
			backface-visibility: hidden;
		}

		.color-card-back {
			transform: rotateY(180deg);
		}

		h4 {
			font-size: 20px;
			line-height: 24px;
			font-weight: 600;
			margin: 0 0 5px;
			color: #fff;
		}
		p {
			font-size: 14px;
			line-height: 18px;
			color: #fff;
			margin: 0;

			@media (max-width: 1699px) {
				&.scale-down {
					transform: scale(0.85);
				}
			}
			@media (max-width: 1280px) {
				&.scale-down {
					transform: scale(0.75);
				}
			}
		}
		&.fern {
			background-color: var(--fern);
		}
		&.silver {
			background-color: var(--silver-chalice);
		}
		&.cyan {
			background-color: #98c1d9;
		}
		&.bluish-cyan {
			background-color: #429ebd;
		}
		&.light-cyan {
			background-color: #95bccc;
		}
		&.light-blue {
			background-color: #48b5d6;
		}
		&.blue {
			background-color: #0196c1;
		}
		&.purple {
			background-color: #af87ce;
		}
		&.secondary {
			background-color: #6e86b3;
		}
		&.red {
			background-color: #da809f;
		}
		&.orange {
			background-color: var(--tan-hide);
		}
	}
}

.no-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;

	.no-content-icon {
		flex: 0 0 auto;
		width: 100px;
		margin: 0 0 24px;
	}

	.no-content-text {
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		text-align: center;

		a {
			color: var(--theme-primary);
		}
	}
}
.custom-image-wrap,
.custom-avatar-wrap {
	margin-right: 14px;
	flex: 0 0 auto;
	width: 56px;
	height: 56px;
	background-color: #8e99a3;
	border-radius: 12px;
	font-size: 18px;
	font-weight: 700;
	color: #fff;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.custom-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 10px;
	}

	.ant-upload {
		.ant-upload-select-picture-card {
			width: 140px;
			height: 80px;
			margin-right: 0;
			margin-bottom: 0;

			i {
				margin-bottom: 6px;
				font-size: 18px;
			}
		}
	}
}
.custom-image-wrap {
	border-radius: 10px;
}
.page-sub-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 16px;

	.sub-title {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		margin: 0;
	}
}

.custom-back-btn {
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	line-height: 14px;
	color: #3076bb;
	cursor: pointer;
	font-weight: 700;
	margin: 0 0 14px;

	i {
		margin-right: 8px;
	}

	&:hover,
	&:focus,
	&:active {
		color: #40a9ff;
	}
}

.icon-orange {
	color: var(--tan-hide);
}

.text-dark-gray {
	color: #333;
}

.text-gray {
	color: #999;
}

.text-blue {
	color: var(--theme-primary);
}

.text-secondary {
	color: #6e86b3;
}

.text-orange {
	color: var(--tan-hide);
}

.text-green {
	color: var(--forest-green);
}

.text-danger {
	color: #f43943 !important;
}

.text-italic {
	font-style: italic;
}

.cursor-p {
	cursor: pointer;
}

.ant-radio-group {
	display: inline-flex;
	flex-wrap: wrap;
	color: #000;
	font-size: 14px;

	.ant-radio-button-wrapper {
		height: auto;
		padding: 8px;
		line-height: 18px;
		min-width: 120px;
		text-align: center;
		border: 1px solid var(--theme-primary);
		color: var(--theme-primary);
		border-radius: 18px;

		&:not(:last-child) {
			margin-right: 14px;
		}

		&:not(:first-child) {
			&::before {
				content: none;
			}
		}

		&:first-child,
		&:last-child {
			border-radius: 18px;
		}

		&-checked {
			&:not(.ant-radio-button-wrapper-disabled) {
				color: var(--theme-primary);
				background: #fff;
				border-color: var(--theme-primary);
				-webkit-box-shadow: -1px 0 0 0 var(--theme-primary);
				box-shadow: -1px 0 0 0 var(--theme-primary);

				&:first-child,
				&:last-child {
					border-color: var(--theme-primary);
					background-color: var(--theme-primary);
				}
			}
		}

		&:hover {
			color: var(--theme-primary);
			background-color: var(--theme-primary);
			color: #fff;
		}
	}
}

.ant-radio-group-solid {
	.ant-radio-button-wrapper-checked {
		&:not(.ant-radio-button-wrapper-disabled) {
			color: #fff;
			background: var(--theme-primary);
			border-color: var(--theme-primary);

			&:hover,
			&:active {
				background: var(--theme-primary);
				border-color: var(--theme-primary);
				color: #fff;
			}

			&:focus-within {
				outline: 0;
			}
		}
	}
}

.custom-loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: transparent;
	z-index: 1;
	text-align: center;

	img {
		max-width: 100%;
		height: auto;
	}

	.custom-loader-icon,
	i {
		font-size: 60px;
		line-height: 1;
		color: #00295d;
	}

	.custom-loader-text {
		font-size: 18px;
		line-height: 18px;
		font-weight: 600;
		color: #000;
		margin: 20px 0 0;
	}
}

.custom-upload-picture {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 14px;

	.ant-upload-picture-card-wrapper {
		margin: 0 8px 8px 0;

		.ant-upload-list {
			margin: 0 8px 0 0;
		}
	}

	.ant-upload-list-picture-card {
		.ant-upload-list-picture-card-container {
			width: 86px;
			height: 86px;
			margin: 0;
		}

		.ant-upload-list-item {
			width: 86px;
			height: 86px;
			margin: 0;
			border-radius: 10px;
			border-color: #c9c9c9;
			padding: 0;
			overflow: hidden;
			background: #ddd;

			&-thumbnail {
				img {
					object-fit: contain;
				}
			}
		}

		.ant-upload-list-item-actions {
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			transform: none;

			a {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.ant-upload-select-picture-card {
		background: #fafdff;
		border-color: #c9c9c9;
		margin: 0 0 0 8px;
		width: 86px;
		height: 86px;
		border-radius: 10px;
	}

	.hide-upload-btn {
		.ant-upload-select-picture-card {
			transition: all 0.1s ease;
			transform: scale(0);
		}
	}

	.upload-picture-btn {
		i {
			font-size: 22px;
			color: #1d8dd4;
			margin-bottom: 6px;
		}

		.upload-picture-text {
			font-size: 14px;
			line-height: 14px;
			font-weight: 600;
			margin: 0;
			color: #1d8dd4;
		}
	}

	.cancel-picture-btn {
		color: #f43943;
		font-size: 12px;
		line-height: 12px;
		font-weight: 700;
		display: inline-flex;
		align-items: center;
		cursor: pointer;

		i {
			margin-right: 4px;
			font-size: 14px;
		}
	}
}

.ant-popover-inner {
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.ant-modal-mask {
	background-color: rgba(29, 29, 29, 0.7);
}

.ant-modal-content {
	border-radius: 18px;
}

.ant-modal-close {
	top: 24px;
	right: 20px;
	outline: none;
}

.ant-modal-confirm {
	.ant-modal-body {
		padding: 20px;
	}

	.ant-modal-close {
		+ .ant-modal-body {
			padding-top: 20px;
		}
	}
}

.ant-modal-close-x {
	width: 16px;
	height: 16px;
	font-size: 16px;
	line-height: 16px;

	.ant-modal-close-icon {
		display: block;
	}
}

.ant-modal-header {
	padding: 20px 40px;
	background: transparent;
	border-bottom: 0;
	border-radius: 18px 18px 0 0;

	+ .ant-modal-body {
		padding-top: 0;
	}

	&.inside-body {
		margin: -20px -20px 0 -20px;
	}
}

.ant-modal-title {
	font-weight: 700;
	text-align: center;
	color: #000;
	font-size: 26px;
	line-height: 30px;

	.ant-modal-title-small {
		font-size: 12px;
		font-weight: 500;
		color: #999;
	}
}

.ant-modal-body {
	padding: 20px;
	font-size: 14px;
	line-height: 1.5;
	word-wrap: break-word;
	font-family: 'Nunito Sans', sans-serif;

	p {
		font-size: 16px;
		line-height: 22px;
		font-weight: 400;
		margin: 0 0 14px;

		&.small {
			font-size: 12px;
			line-height: 16px;
		}
	}

	.ant-modal-sub-title {
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		margin: 0 0 10px;
	}

	+ .ant-modal-footer {
		padding-top: 0;
	}

	.SortableItem {
		.custom-form-group {
			margin: 0 0 20px;

			label {
				margin-bottom: 8px;
				line-height: 15px;

				span {
					font-weight: 400;
				}
			}
		}
	}
}

.ant-modal-footer {
	padding: 20px;
	border-top: 0;
	border-radius: 0 0 18px 18px;
}

.upload-link-text {
	.upload-link {
		color: var(--theme-primary);
		margin-bottom: 0;
		margin-right: 6px;
		font-weight: 600;
		cursor: pointer;
	}
}
.upload-file-text {
	display: inline-flex;
	align-items: center;
	margin: 0 0 6px;

	p {
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
		margin: 0;
	}

	i {
		margin-left: 6px;
	}
}

.share-qr-code {
	margin: 0 0 14px;

	img {
		display: block;
		margin: 0 auto;
		width: 160px;
	}
}

.custom-search-grp {
	margin-bottom: 14px;

	.ant-input-group {
		display: flex;

		.ant-input {
			height: auto;
			flex: 1 1 auto;
			border-radius: 18px 0 0 18px;
		}
		button {
			height: 100%;
			flex: 1 1 auto;
			border-radius: 0 42px 42px 0 !important;
		}
		.ant-input-group-addon {
			width: auto;
		}
	}
}

.share-social-icons {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0 0 14px;

	li {
		width: 60px;
		text-align: center;
		&.ss-icon-disable {
			pointer-events: none;
			opacity: 0.5;
		}
		.ss-icon-text {
			color: #000;
			font-size: 12px;
			font-weight: 600;
			margin-top: 4px;
		}
	}
}

.ant-modal-lg {
	&.ant-modal-wrap {
		.ant-modal {
			max-width: 1100px;
			width: 100% !important;
		}
	}
}

.text-note {
	font-size: 12px;
	line-height: 14px;
	font-weight: 600;
	font-style: italic;
	margin: 0 0 10px;
}

.error-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	.error-container {
		width: 80%;
	}
}

.title-with-action {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.rotate {
	animation: rotation 2s infinite linear;
}
.rotate-inertia {
	animation: rotation 2s 0s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

.floating {
	animation-name: floating;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

@keyframes floating {
	0% {
		transform: translate(0, 0px);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, 0px);
	}
}

.custom-page-wrapper {
	img {
		max-width: 100%;
		height: auto;
	}
}

@media (max-width: 1366px) and (min-width: 1200px) {
	.filter-wrap {
		.ant-select {
			min-width: 160px;
			margin-right: 10px;
		}
	}
}

@media (max-width: 1199px) {
	.ant-modal-lg {
		&.ant-modal-wrap {
			.ant-modal {
				max-width: calc(100vw - 16px);
			}
		}
	}
}

@media (max-width: 991px) {
	.custom-filter {
		flex-wrap: wrap;

		.filter-btn {
			width: 42px;
			height: 42px;
			line-height: 33px;
			margin-left: auto;
		}

		.filter-wrap {
			flex: 0 0 100%;
		}
	}
}

@media (max-width: 767px) {
	.page-title {
		flex-direction: column;
		align-items: flex-start;

		.page-heading {
			font-size: 22px;
			margin: 0 0 10px;
		}

		.gift-card-title-wrap {
			margin: 0 0 10px;
			justify-content: space-between;
			width: 100%;
		}

		.info-action {
			flex-wrap: wrap;

			& > li {
				width: auto;
				margin: 0 0 10px;
			}
		}

		.custom-back-btn {
			margin: 0 0 14px;
		}
	}

	.tag-wrap {
		.tag {
			.tag-name {
				font-size: 14px;
				line-height: 20px;
			}

			.tag-close {
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}

	.custom-card {
		padding: 16px;
	}

	.custom-form-group {
		margin: 0 0 20px;

		.custom-input {
			min-height: 38px;
			padding: 10px 16px;
		}

		.custom-label {
			font-size: 14px;
			line-height: 18px;
		}
	}

	.search-input {
		margin-bottom: 15px;
		margin-right: 0;
	}

	.custom-filter {
		.filter-wrap {
			.ant-select {
				min-width: calc(50% - 10px);

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			.close-btn {
				min-width: calc(50% - 10px);
			}
		}
	}

	.job-card-wrap {
		.ant-spin-container {
			.ant-row {
				.ant-col {
					width: 100%;
				}
			}
		}
	}

	.custom-image-wrap {
		margin-bottom: 14px;
	}

	.ant-modal-content {
		border-radius: 12px;
	}

	.ant-modal-close {
		top: 21px;
		right: 16px;
	}

	.ant-modal-header {
		padding: 18px 32px;
		border-radius: 12px 12px 0 0;
	}

	.ant-modal-body {
		padding: 18px 16px;
	}

	.ant-modal-footer {
		padding: 18px 16px;
	}
}

@media (max-width: 479px) {
	.gift-card-title-wrap {
		flex-direction: column;

		.custom-form-group {
			margin: 8px 0 !important;
		}
	}
}

.custom-btn-group {
	.ant-btn {
		margin: 0 8px 0px 0;
		height: 40px;
	}
}

.autocomplete-root {
	position: relative;

	.autocomplete-dropdown-container {
		top: 100%;
		left: 0;
		position: absolute;
		z-index: 999;

		.suggestion-item {
			padding: 6px;
			cursor: pointer;
			background-color: #ffffff;
			transition: background-color 0.3s ease-in;

			&.active {
				background-color: #e6e6e6;
			}
		}
	}
}

.ant-form-item-control {
	.custom-form-group {
		margin: 0;
	}
}

.add-company-form {
	display: flex;
	align-items: center;
}

//Collapse Panel
.custom-collapse-wrapper {
	background-color: #fff;
	display: inline-flex;
	margin: 0 0 18px;

	.custom-collapse-panel {
		border: 0;

		.ant-collapse-header {
			padding: 10px 0;
			display: flex;
			align-items: center;

			h4 {
				font-size: 14px;
				line-height: 14px;
				margin: 0;
				font-weight: 700;
				margin-left: 8px;
				display: inline-block;
			}

			.custom-collapse-icon {
				display: inline-block;
				font-size: 8px;
				transition: all 0.3s ease;
				transform: rotate(-90deg);
			}

			.ant-collapse-arrow {
				display: none;
			}
		}

		.ant-collapse-content-box {
			padding: 0;
			padding-top: 14px;
		}

		&.ant-collapse-item-active {
			.custom-collapse-icon {
				transform: rotate(0deg);
			}
		}
	}
}

//ToolTip
.google-visualization-tooltip {
	padding: 6px !important;

	.google-visualization-tooltip-item-list {
		margin: 0;
		padding: 0;

		.google-visualization-tooltip-item,
		.google-visualization-tooltip-item:first-child {
			margin: 0;
			padding: 0;
			white-space: nowrap;

			span {
				font-size: 11px !important;
				line-height: 16px;
			}
		}
	}
}

.RichTextEditor__editor___1QqIU {
	font-family: 'Nunito Sans', sans-serif;
}

.ant-popover-message {
	.icon-envelope-outline {
		position: absolute;
		left: 0;
		top: 6px;
	}
}

.social-wrap {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-left: 2px;

	> * {
		margin-right: 6px;
	}
}

.tab-list {
	display: flex;
	list-style: none;
}

.ant-select-selection-item-remove {
	color: #fff;
}

.ant-input-suffix {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 12px;
}

.ant-table-fixed {
	table-layout: fixed;
}

.ant-table-tbody > tr > td {
	word-break: break-word;
}

.ant-select-open {
	.ant-select-arrow {
		transform: rotate(180deg);
	}
}
.ant-select-show-search {
	.ant-select-arrow {
	}
}
.ant-select {
	.ant-select-arrow {
		transition:
			transform 0.3s,
			-webkit-transform 0.3s;
	}
}
.ant-select-focused {
	&.ant-select-show-arrow {
		.ant-select-selector {
			box-shadow: none !important;
		}
	}
}
.ant-legacy-form-item-control {
	line-height: normal;
}
.d-flex.align-items-center.justify-content-start {
	min-height:38px;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	align-content: center;
}

.ant-select.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
	min-height: 38px;
	.ant-select-selector {
		min-height: 38px;
		border-radius: 8px;
	}
}