.internal-banner {
  position: relative;
  width: calc(100% + 30px);
  padding-bottom: 22%;
  margin: -16px 0 22px -16px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }
  .banner-img {
    position: absolute;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .announcements-banner-edit {
    z-index: 2;
    position: relative;
    text-align: right;
    padding: 25px 25px 6px;
  }
  .edit-banner {
    top: 20px;
    right: 20px;
    position: absolute;
    background: #fff;
    z-index: 9;
    padding: 4px 6px;
    display: flex;
    border-radius: 4px;
  }
  .banner-content {
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 30px;
    top: 50%;
    transform: translateY(-50%);

    .c-left {
      flex: 1;
      margin-right: 15px;

      p {
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        margin: 0 0 18px;
        strong {
          font-weight: 900;
          display: block;
        }
      }
    }
    .c-right {
      p {
        color: #ffffff;
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 18px;
        strong {
          font-weight: 900;
          font-size: 20px;
          display: block;
        }
      }
      .announcements-update-btn {
        font-size: 17px;
        line-height: 24px;
        background: transparent;
        border: 0;
        height: auto;
        color: #fff;
        font-weight: 600;
        padding: 8px 20px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border: 3px solid;
          border-image-source: linear-gradient(to left, #3076bb, #ee3b40);
          border-image-slice: 1;
        }
        &:hover,
        &:focus,
        &:active {
          background-image: linear-gradient(to left, #3076bb, #ee3b40);
        }
        span {
          line-height: 40px;
          height: 32px;
        }
      }
    }
  }
}

.stat-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  height: 100%;
  min-height: 180px;

  .stat-box-info {
    top: 8px;
    right: 8px;
    position: absolute;
    color: #fff;
    font-size: 20px;
  }

  h4 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }
  p {
    color: #fff;
    margin: 0;
  }

  &.nearby {
    background-color: var(--fern);
  }
  &.department {
    background-color: var(--deep-blush);
  }
  &.all {
    background-color: var(--royal-blue);
  }
}
.card-row.recommended {
  height: 100%;
  margin: 0 16px;

  .job-card {
    min-height: 180px;
  }

  .ant-col {
    margin: 0 !important;

    .ant-list-item {
      margin: 0;
      height: 100%;
    }
  }

  .ant-list-pagination {
    top: calc(50% - 16px);
    left: 0;
    right: 0;
    height: 0;
    margin: 0 -16px;
    position: absolute;
    transform: translateY(-50%);

    .ant-pagination {
      height: 0;
      display: flex;
      justify-content: space-between;

      &::after {
        display: none;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-item,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        display: none;
      }
    }
  }

  .no-content {
    margin: 0;
  }
}
.your-applications-wrap {
  margin: 0 0 30px;
}
.your-applications {
  margin: 0 16px;
  min-height: 160px;

  .ant-row {
    display: flex;
  }
  .ant-col {
    height: 100%;
  }

  .ant-list-item {
    margin: 0 !important;
  }
  .ant-list-pagination {
    top: calc(50% - 16px);
    left: 0;
    right: 0;
    height: 0;
    margin: 0 -16px;
    position: absolute;
    transform: translateY(-50%);

    .ant-pagination {
      height: 0;
      display: flex;
      justify-content: space-between;

      &::after {
        display: none;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-item,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        display: none;
      }
    }
  }
}
.progress-wrap {
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #dddddd;
  min-height: calc(100% - 44px);

  .progress-circle-wrap {
    display: flex;
    justify-content: space-around;
    margin: 0 0 12px;

    .ant-progress {
      .ant-progress-text {
        font-size: 0.75em;
      }
    }
  }
  .progress-step-container {
    display: flex;
    justify-content: space-around;

    .progress-circle {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid transparent;
      position: relative;
      margin: auto;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        color: #fff;
      }
    }
    .progress-text {
      font-size: 13px;
      color: #000;
      margin-top: 6px;
      line-height: 18px;
    }
  }
}

.im-resource {
  margin: 0 0 30px;

  .resource-box {
    background-color: #fff;
    padding: 20px 12px 12px;
    border: 1px solid #dddddd;
    border-radius: 12px;
    height: 180px;
    position: relative;

    .ant-btn-link {
      top: 2px;
      right: 8px;
      position: absolute;
      margin: 0;
    }

    .resource-content {
      height: 100%;
      overflow-y: auto;

      & * {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .internal-banner {
    padding-bottom: 34%;
  }
  .im-resource {
    margin-bottom: 10px;

    .resource-box {
      margin-bottom: 20px;
    }
  }
  .progress-wrap {
    min-height: auto;
    margin-bottom: 20px;
  }
}
@media (max-width: 640px) {
  .internal-banner {
    padding-bottom: 40%;

    .banner-content {
      flex-direction: column;

      .c-left {
        margin: 0 0 16px;

        p {
          strong {
            display: inline-block;
          }
        }
      }
      .c-right {
        p {
          strong {
            display: inline-block;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .internal-banner {
    padding-bottom: 80%;
  }
}
