.career-heading-wrap {
  margin-bottom: 5px;
  span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .ant-btn {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .career-heading {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}
.work-history-details {
  display: flex;
  align-items: flex-start;
  width: 100%;
  .work-history-left {
    display: flex;
    flex: 1;
    margin-right: 16px;
    p {
      font-size: 16px;
      line-height: 21px;
      margin: 0 0 5px;
      word-break: break-word;
    }
    .job-details {
      flex: 0 0 45%;
      padding-right: 15px;
      margin: 0 0 8px;
    }
    .job-description {
      flex: 0 0 55%;
      margin: 0 0 8px;
    }
  }
  .work-history-right {
    margin-left: auto;
    margin-bottom: 5px;
  }
}

.career-card {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 16px;
  padding: 16px 20px;
  padding-bottom: 36px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .career-card-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;

    .career-card-heading {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
      margin-bottom: 14px;
      margin-right: 10px;
    }
  }
  .career-card-body {
    display: flex;
    justify-content: center;
  }
  .career-card-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
    flex-grow: 1;

    .remove-all-button {
      color: var(--flamingo);
    }
    .remove-all-button:focus {
      color: var(--flamingo);
    }
    .remove-all-button:hover,
    .remove-all-button:active {
      color: var(--sunset-orange);
    }
  }
}
.new-role-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 30px 22px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  .nr-card-left {
    max-width: 40%;
    width: 100%;
    display: flex;
  }
  .nr-card-right {
    max-width: 58%;
    width: 100%;
  }
  .nrc-right-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 20px;
  }
  .nrc-user-detail {
    flex: 1;
    .nr-name {
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      margin: 0 0 20px;
    }
    .nrc-user-link {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      word-break: break-word;
      font-weight: 600;
      margin: 0 0 12px;
      flex-wrap: wrap;

      i {
        color: #8e99a3;
        margin-right: 10px;
        width: 24px;
      }
    }
  }
  .nrc-user-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin: 0 0 12px;
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;

    span {
      font-weight: 700;
      margin-right: 6px;
    }
  }
  .nrc-right-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .nrc-right-inner {
      max-width: 49%;
      width: 100%;
    }
  }
}
.new-role-title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}
.career-history {
  .divider {
    margin-top: 0;
  }
  .ch-title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    margin: 0 0 14px;
  }
  .tag-wrap {
    .tag {
      cursor: revert;
      .tag-name {
        &::after {
          content: none;
        }
      }
    }
    + .divider {
      margin-top: 16px;
    }
  }
  .ch-history-wrap {
    max-height: 350px;
    overflow-y: auto;
  }
  .ch-history {
    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 14px;
      padding-bottom: 6px;
    }
    .ant-list-item {
      padding: 0;
    }
    .ch-text {
      font-size: 14px;
      list-style: 20px;
      margin: 0 0 8px;
      word-break: break-word;
    }
  }
  .ch-subtitle {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin: 0 0 4px;
    color: #838383;
  }
}
@media (max-width: 1199px) {
  .work-history-details {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .work-history-left {
      flex: 0 0 100%;
      margin-right: 0;
      order: 1;
    }
  }
  .new-role-card {
    .nr-card-left {
      max-width: 49%;
      flex-wrap: wrap;
    }
    .nrc-user-detail {
      flex: 0 0 100%;
    }
    .nr-card-right {
      max-width: 49%;
    }
    .nrc-right-wrap {
      .nrc-right-inner {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 767px) {
  .work-history-details {
    .work-history-left {
      flex-wrap: wrap;
      .job-details {
        flex: 0 0 100%;
        padding-right: 0;
        margin: 0 0 15px;
      }
      .job-description {
        flex: 0 0 100%;
        margin: 0;
      }
    }
  }
  .career-card {
    padding: 16px;
  }
  .new-role-card {
    padding: 18px 16px;
    flex-wrap: wrap;

    .nr-card-left {
      max-width: 100%;
    }
    .nr-card-right {
      max-width: 100%;
    }
  }
}
