.page-top-filter {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 0 0 20px;
	flex-wrap: wrap;

	.filter-wrap {
		margin-right: 10px;
		flex: 1 1 auto;

		.search-input {
			max-width: 320px;
		}
	}
}
.viewing-nearby {
	width: 280px;
	text-align: center;

	h4 {
		color: #f43943;
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
		margin: 0 0 12px;
	}
}
.job-card-wrap {
	.match-ribbon {
		width: 7px;
		height: 60px;
		top: 0;
		left: 0;
		position: absolute;

		&.fair {
			background-color: #cccccc;
		}
		&.excellent {
			background-color: #19bb4b;
		}
		&.good {
			background-color: #ffa850;
		}
	}

	.ant-spin-container {
		.ant-row {
			display: flex;
			flex-wrap: wrap;

			.ant-col {
				height: 100%;
				padding-bottom: 16px;
				&.manage-job-card {
					padding-bottom: 0;
				}
			}
		}
	}
	.job-card {
		height: 100%;
		border: 1px solid #dddddd;
		border-radius: 16px;
		background-color: #fff;
		overflow: hidden;
		padding: 14px;
		margin-bottom: 0;

		&.manage-job-card {
			height: 280px;
		}

		.job-close-btn {
			top: 6px;
			right: 6px;
			position: absolute;
		}

		.job-match-quality {
			top: 0;
			left: 0;
			position: absolute;
			width: 10px;
			height: 60px;
		}

		.job-card-inner {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.job-card-header {
				margin: 0 0 12px;

				&.manage-job-card {
					height: 125px;
				}

				.job-title-wrap {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					margin: 0 0 16px;

					.job-title {
						color: #262626;
						font-size: 16px;
						line-height: 24px;
						font-weight: 700;
						margin: 0;

						a {
							color: #262626;
						}
						a:hover {
							color: #757575;
						}
					}
					.actions {
						margin: 0 0 0 auto;
						padding: 0 0 0 8px;
						list-style: none;
						display: flex;
						align-items: center;

						li {
							display: flex;

							& + li {
								margin-left: 6px;
							}

							.amount {
								color: var(--forest-green);
								font-size: 16px;
								line-height: 24px;
								font-weight: 700;
							}
						}
					}
					.social-icons {
						display: flex;
						align-items: center;
						height: 24px;

						a {
							display: flex;
							margin: 0 4px;

							i {
								color: var(--dove-gray);
								font-size: 13px;
							}
							svg {
								fill: var(--dove-gray);
							}
						}
					}
				}
				.job-info-wrap {
					display: flex;

					.job-info {
						margin: 0;
						padding: 0;
						list-style: none;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						flex: 1;

						li {
							display: flex;
							font-size: 14px;
							line-height: 14px;
							color: var(--oslo-gray);
							align-items: center;
							margin-bottom: 12px;

							i {
								color: var(--oslo-gray);
								margin-right: 4px;
								font-size: 18px;
							}

							&:not(:last-child) {
								margin-right: 16px;
							}
						}
					}
					.share {
						margin-left: 12px;
						color: var(--theme-primary);
						cursor: pointer;
					}
				}

				.job-bonus {
					font-size: 14px;
					line-height: 20px;
					font-weight: 700;
					color: var(--oslo-gray);
					margin: 0 0 12px;

					.job-bonus-amt {
						color: var(--forest-green);
					}
				}
				.job-id {
					font-size: 13px;
					line-height: 20px;
					color: var(--oslo-gray);
					margin: 0 0 12px;
				}
			}
			.job-card-footer {
				&.manage-job-card {
					height: 105px;
				}
				.job-status {
					margin: 0 0 24px 0;
					padding: 0;
					list-style: none;
					display: flex;
					align-items: flex-start;
					justify-content: space-around;
					&.manage-job-card {
						height: 55px;
					}
					li {
						text-align: center;

						.job-data {
							color: var(--oslo-gray);
							font-size: 24px;
							line-height: 34px;
							font-weight: 700;
							margin: 0;
						}
						.job-data-type {
							color: var(--oslo-gray);
							font-size: 12px;
							line-height: 14px;
							font-weight: 400;
							margin: 0;
						}
					}
				}
				.job-card-bottom {
					background-color: var(--silver-chalice);
					padding: 15px 15px 5px;
					color: #fff;
					display: flex;
					justify-content: space-between;
					font-size: 12px;
					flex-wrap: wrap;
					margin: 0 -14px -14px;
					&.manage-job-card {
						height: 50px;
					}

					.card-bottom {
						display: flex;
						align-items: center;
						margin-bottom: 10px;

						[class*='icon-'] {
							font-size: 10px;
						}

						.name {
							margin-left: 4px;
						}
					}
				}
				.ant-skeleton-button-lg {
					height: 42px;
				}

				.ant-btn-block + .ant-btn-block,
				.ant-skeleton-block + .ant-skeleton-block {
					margin-top: 8px;
				}
			}
		}
	}
	.ant-list-pagination {
		margin-top: 0;
		text-align: center;
	}
}
.browse-job-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
	padding: 16px;
	margin: 0 0 20px;
}
.bjc-top {
	display: flex;
	margin: 0 0 16px;

	.bjc-left {
		flex: 1;
		margin-right: 14px;

		ul {
			padding: 0;
			list-style: none;
			margin: 0 0 10px;
			display: flex;
			align-items: center;

			li {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: var(--oslo-gray);
				line-height: 14px;
				margin: 0 0 10px;

				i {
					color: var(--oslo-gray);
					margin-right: 4px;
					font-size: 18px;
				}

				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}
	.bjc-name {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		margin: 0 0 10px;
	}
	.bjc-match {
		font-size: 14px;
		display: flex;
		align-items: center;
		font-weight: 400;
		margin: 0 0 10px;

		i {
			color: var(--forest-green);
			margin-right: 10px;

			+ span {
				font-weight: 700;
				margin-right: 4px;
			}
		}
	}
	.bjc-details {
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		margin: 0 0 14px;

		span {
			margin-right: 4px;
			font-weight: 700;
			min-width: 60px;
			display: inline-block;
		}
	}
	.bjc-btn-grp {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 36px;

		.ant-btn + .ant-btn {
			margin-left: 8px;
		}

		.btn-block {
			+ .btn-block {
				margin-top: 0;
				margin-left: 10px;
			}
		}
	}
	.bjc-bonus-share {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 16px;

		p {
			font-size: 14px;
			line-height: 14px;
			font-weight: 600;
			margin: 0 0 0 10px;
			display: flex;

			.bjc-bonus-point {
				color: var(--forest-green);
				margin-left: 2px;
			}
			.bjc-hotjob {
				color: var(--tan-hide);
				margin-left: 10px;
			}
		}
		.share-link {
			margin-left: 10px;
		}
	}
}
.bjc-description {
	margin-bottom: 20px;

	.bjc-description-title {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin: 0 0 10px;
	}
	.bjc-description-content {
		.ant-btn-link {
			margin-top: 12px;
		}
	}
}
.bjc-recruiter {
	font-size: 16px;
	line-height: 16px;
	font-weight: 400;
	margin-top: auto;
	margin: 0;

	a {
		font-weight: 700;
		margin-left: 6px;
		display: inline-flex;

		i {
			font-size: 14px;
			margin-left: 6px;
		}
	}
}
.browse-job-referral {
	.bjr-title {
		font-size: 20px;
		line-height: 20px;
		font-weight: 700;
		margin-bottom: 20px;
	}
}
.share-link {
	font-size: 14px;
	line-height: 14px;
	display: flex;
	align-items: center;
	font-weight: 400;

	i {
		font-size: 12px;
		margin-right: 4px;
	}
}
.manage-job-details {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 36px 30px 24px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;

	.mjd-heading {
		font-size: 22px;
		line-height: 26px;
		font-weight: 700;
		margin: 0 0 14px;
	}
	.mjd-location {
		padding: 0;
		list-style: none;
		margin: 0 0 10px;
		display: flex;
		align-items: center;

		li {
			display: flex;
			align-items: center;
			font-size: 14px;
			color: #000;
			line-height: 14px;
			margin: 0 0 10px;

			i {
				color: #afb1b3;
				margin-right: 4px;
				font-size: 18px;
			}

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
	.mjd-job-data {
		font-size: 14px;
		line-height: 14px;
		font-weight: 700;
		margin: 0 0 14px;

		span {
			font-weight: 400;
			margin-left: 6px;

			&.bunus {
				color: var(--forest-green);
			}
		}
	}
	.mjd-subheading {
		font-size: 20px;
		line-height: 20px;
		font-weight: 700;
		margin: 0 0 18px;
	}
	.mjd-job-description {
		margin-top: 30px;
		margin-bottom: 20px;

		.mjd-content {
			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				font-family: 'Nunito Sans', sans-serif !important;
				margin: 0 0 14px;

				font {
					font-family: 'Nunito Sans', sans-serif !important;
				}
			}
			h1 {
				font-size: 24px;
				font-weight: 700;
			}
			h2 {
				font-size: 22px;
				font-weight: 700;
			}
			h3 {
				font-size: 20px;
				font-weight: 700;
			}
			h4 {
				font-size: 18px;
				font-weight: 700;
			}
		}

		.view-more {
			display: inline-flex;
			align-items: center;
			color: var(--theme-primary);
			font-size: 15px;
			line-height: 20px;
			font-weight: 600;
			cursor: pointer;

			.view-more-icon {
				margin-left: 6px;
				font-size: 8px;
			}
		}
	}
	.mjd-recruiter {
		font-size: 16px;
		line-height: 16px;
		font-weight: 400;

		.mjd-recruiter-name {
			color: #3076bb;
			font-weight: 700;
			margin-left: 6px;
		}
	}
}
.select-language {
	&.custom-form-group {
		margin: 0;

		.ant-select {
			.ant-select-selector {
				background: transparent;
				border: none;

				&:active {
					box-shadow: none;
				}

				&__rendered {
					margin-left: 0;
				}
				.ant-select-selection-item {
					div {
						justify-content: flex-end;
					}
				}
			}
		}
	}
}
.select-language-value {
	display: flex;

	span {
		margin-right: 6px;
	}
}
.ant-select-dropdown {
	.select-language-value {
		color: rgba(0, 0, 0, 0.65);
	}
}
.referral-language {
	left: auto !important;
	right: 10px !important;
}
.referral.select-language {
	display: flex;
	height: 38px;
	align-items: center;

	.ant-select {
		.ant-select-selector {
			.ant-select-selection-placeholder {
				position: static;
				margin-top: 0;
				height: auto;
			}
			.ant-select-arrow {
				color: #fff;
			}
		}
	}
	.ant-select-arrow {
		color: #fff;
	}
}
.referral-finder {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.rf-left {
		flex: 0 0 48%;
	}
	.rf-right {
		flex: 0 0 48%;

		.no-content {
			margin-top: 0;
			height: 100%;
			justify-content: center;
		}
	}
	.rf-card {
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 15px;
		padding: 40px 30px;

		.rf-title {
			font-size: 22px;
			font-weight: 700;
			line-height: 22px;
			margin: 0 0 20px;
		}
		.rf-text {
			font-size: 14px;
			line-height: 18px;
			font-weight: 400;
			margin: 0 0 50px;
		}
		.rf-subtitle {
			font-size: 18px;
			line-height: 18px;
			font-weight: 700;
			margin: 0 0 10px;
		}
		.custom-form-group {
			.ant-form-item-children {
				flex-direction: column;
			}
		}
		.ant-radio-group {
			display: flex;
			margin: 0 0 16px;

			.ant-radio-button-wrapper {
				flex: 1;
			}
		}
		.ant-btn-primary {
			width: 100%;
		}
	}
}
.referral-form-share-link {
	.ant-input-group-addon {
		background-color: #d8e7f3;
	}
}
.modal-job-info-wrap {
	padding: 12px 14px;
	background-color: #f0faf0;
	border-radius: 10px;
	margin-bottom: 20px;
	border: 2px solid #318a39;

	.mj-info-wrap {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 0 0 12px;

		.mj-info-name {
			font-size: 16px;
			line-height: 20px;
			font-weight: 700;
			margin: 0 16px 0 0;

			a {
				color: inherit;
			}
		}
		.mj-info-bonus {
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			list-style: none;

			> li {
				font-size: 16px;
				line-height: 20px;
				font-weight: 700;

				+ li {
					margin-left: 12px;
				}
			}
		}
	}
	.mj-info {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;

		li {
			display: flex;
			font-size: 13px;
			line-height: 16px;
			font-weight: 600;
			color: var(--oslo-gray);
			align-items: center;

			i {
				color: var(--oslo-gray);
				margin-right: 4px;
				font-size: 16px;
			}
			+ li {
				margin-left: 16px;
			}
		}
	}
	.mj-info-text {
		font-size: 14px;
		line-height: 14px;
		font-weight: 600;
		margin: 0 0 10px;

		span {
			font-weight: 400;
			margin-left: 6px;
		}
	}
}
.email-withlabel {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;

	span {
		display: block;
		font-weight: 600;
		margin: 0 0 4px;
	}
}
.general-job-tag {
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	padding: 4px 6px;
	border-radius: 18px;
	color: #fff;
	white-space: nowrap;
	background-color: var(--fern);
}

@media (max-width: 1280px) {
	.referral-finder {
		.rf-card {
			padding: 30px 22px;
		}
	}
}
@media (max-width: 1199px) {
	.referral-finder {
		.rf-left,
		.rf-right {
			flex: 0 0 100%;

			.no-content {
				margin-top: 40px;
			}
		}
	}
}
@media (max-width: 767px) {
	.bjc-top {
		flex-wrap: wrap;

		.bjc-left {
			flex: 1 1;
			margin-right: 0;
		}
		.bjc-btn-grp {
			margin-bottom: 14px;
		}
		.bjc-bonus-share {
			justify-content: space-between;
			flex-wrap: wrap;

			.share-link {
				margin-left: 14px;
			}
		}
	}
	.referral-finder {
		.rf-card {
			padding: 18px 16px;

			.ant-radio-group {
				.ant-radio-button-wrapper {
					flex: 0 0 100%;

					+ {
						.ant-radio-button-wrapper {
							margin-top: 8px;
						}
					}
				}
			}
		}
	}
}

.ant-row {
	.form-item-col {
		margin-bottom: 12px;
	}
}

.rfb-item {
	margin-top: 6px;
	margin-bottom: 6px;
}
