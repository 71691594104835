.approval-bonus-table {
    
 .ant-table-wrapper {
        background: white;
        border-radius: 10px;
       width: 1500px;
        .ant-table {
            border-radius: 10px;
        }

        .ant-table-thead tr th {
            background-color: white;
        }

        .ant-table-column-title {
            font-weight: 700;
            // font-size: 16px;
        }
    }
}