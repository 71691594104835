$font-weight: 400;
$background-color: #fff;
$border-color: #efefef;
$text-color: #333;
$handle-color: rgba(0, 0, 0, 0.25);
$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;

@keyframes pop {
	0% {
		transform: scale(1);
		box-shadow: var(--box-shadow);
	}
	100% {
		transform: scale(var(--scale));
		box-shadow: var(--box-shadow-picked-up);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.report-builder-and-custom-report-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.report-builder {
	display: flex;
	flex-direction: column;
	height: 800px;
}

.report-builder-main {
	position: relative;
	transform-style: preserve-3d;
	transition: all 0.75s ease;
	z-index: 1;

	&.flipped {
		transform: rotateY(180deg);
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.13);
		z-index: 2;

		.report-builder-front {
			z-index: -1;
		}
		.report-builder-back {
			z-index: 1;

			.setting-card {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;

				.report-builder-flip-action {
					top: 14px;
					right: 22px;
				}
			}
		}
	}

	.report-builder-inner {
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;

		.setting-heading {
			text-align: center;

			&.absolute {
				position: absolute;
			}
		}
	}

	.report-builder-front,
	.report-builder-back {
		width: 100%;
		height: fit-content;
		position: absolute;
		backface-visibility: hidden;

		.setting-card {
			border-radius: 15px;
		}
	}

	.report-builder-back {
		transform: rotateY(180deg);
		z-index: -1;
	}
}

.report-builder-update-action {
	align-self: flex-end;
	z-index: 3;
	margin: -2.9rem 0 1rem;
	height: 2.125rem;
}

.report-builder-flip-action {
	position: absolute;
	top: 12px;
	right: 12px;

	&.error {
		box-shadow: 0 0 3px 2px var(--flamingo);
	}
}

.report-builder-tab-list {
	display: flex;
	flex-direction: column;
}

.report-builder-wrapper {
	display: flex;
	box-sizing: border-box;
	transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
		scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
	transform-origin: 0 0;
	touch-action: manipulation;
	border-radius: calc(4px / var(--scale-x, 1));
	width: 100%;
	height: fit-content;

	&.fadeIn {
		animation: fadeIn 500ms ease;
	}

	&.dragOverlay {
		--scale: 1.05;
		box-shadow:
			0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05),
			-1px 0 15px 0 rgba(34, 33, 81, 0.01),
			0px 15px 15px 0 rgba(34, 33, 81, 0.25);
		--box-shadow: $box-shadow;
		--box-shadow-picked-up: $box-shadow-border,
			-1px 0 15px 0 rgba(34, 33, 81, 0.01),
			0px 15px 15px 0 rgba(34, 33, 81, 0.25);
		z-index: 999;
		background-color: $background-color;
	}
}

.report-builder-container {
	display: flex;
	flex-direction: column;
	grid-auto-rows: max-content;
	overflow: hidden;
	box-sizing: border-box;
	appearance: none;
	outline: none;
	border-radius: 5px;
	height: 63vh;
	transition: background-color 350ms ease;
	background-color: rgba(246, 246, 246, 1);
	border: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 1em;

	ul {
		display: grid;
		grid-template-columns: repeat(var(--columns, 1), 1fr);
		grid-template-rows: auto;
		align-content: start;
		list-style: none;
		padding: 20px 20px 10px 20px;
		margin: 0;
		height: auto;
		li {
			padding-bottom: 10px;
		}
	}

	&.placeholder {
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: rgba(0, 0, 0, 0.5);
		background-color: transparent;
		border-style: dashed;
		border-color: rgba(0, 0, 0, 0.08);

		&:hover {
			border-color: rgba(0, 0, 0, 0.15);
		}
	}

	&.hover {
		background-color: rgb(235, 235, 235, 1);
	}

	&.unstyled {
		overflow: visible;
		background-color: transparent !important;
		border: none !important;
	}

	&.horizontal {
		width: 100%;

		ul {
			grid-auto-flow: column;
		}
	}

	&.shadow {
		box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
	}

	&:focus-visible {
		border-color: transparent;
		box-shadow:
			0 0 0 2px rgba(255, 255, 255, 0),
			0 0px 0px 2px #4c9ffe;
	}
}

.report-builder-action {
	display: flex;
	width: 12px;
	padding: 10px 10px;
	margin: -4px;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	flex: 0 0 auto;
	touch-action: none;
	cursor: var(--cursor, pointer);
	border-radius: 5px;
	border: none;
	outline: none;
	appearance: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;

	@media (hover: hover) {
		&:hover {
			background-color: var(--action-background, rgba(0, 0, 0, 0.05));

			svg {
				fill: #6f7b88;
			}
		}
	}

	svg {
		flex: 0 0 auto;
		margin: auto;
		height: 100%;
		overflow: visible;
		fill: #919eab;
	}

	&:active {
		background-color: var(--background, rgba(0, 0, 0, 0.05));

		svg {
			fill: var(--fill, #788491);
		}
	}

	&:focus-visible {
		outline: none;
		box-shadow:
			0 0 0 2px rgba(255, 255, 255, 0),
			0 0px 0px 2px $focused-outline-color;
	}
}

.report-builder-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	width: 100%;
	align-items: center;
	padding: 10px 10px;
	background-color: $background-color;
	box-shadow:
		0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
		0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
	outline: none;
	border-radius: calc(4px / var(--scale-x, 1));
	box-sizing: border-box;
	list-style: none;
	transform-origin: 50% 50%;
	-webkit-tap-highlight-color: transparent;

	color: $text-color;
	font-weight: $font-weight;
	font-size: 1rem;

	transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
	transform: scale(var(--scale, 1));

	.report-builder-header-and-actions {
		display: flex;
		align-self: stretch;
		justify-content: space-between;
	}

	.report-builder-label-and-custom-value {
		display: flex;
		align-self: stretch;
		align-items: center;

		label {
			font-size: 12px;
			font-weight: 600;
			text-wrap: nowrap;
			margin: 0 10px 0 0;
		}
	}

	&:focus-visible {
		box-shadow:
			0 0px 4px 1px $focused-outline-color,
			$box-shadow;
	}

	&:not(.withHandle) {
		touch-action: manipulation;
		cursor: grab;
	}

	&.dragging:not(.dragOverlay) {
		opacity: var(--dragging-opacity, 0.5);
		z-index: 0;

		&:focus {
			box-shadow: $box-shadow;
		}
	}

	&.disabled {
		color: #999;
		background-color: #f1f1f1;
		&:focus {
			box-shadow:
				0 0px 4px 1px rgba(0, 0, 0, 0.1),
				$box-shadow;
		}
		cursor: not-allowed;
	}

	&.dragOverlay {
		cursor: inherit;
		transform: scale(var(--scale));
		box-shadow: var(--box-shadow-picked-up);
		opacity: 1;
	}

	&.color:before {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		height: 100%;
		width: 3px;
		display: block;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		background-color: var(--theme-primary);
	}

	&:hover {
		.Remove {
			visibility: visible;
		}
	}
}

.report-builder-actions {
	display: flex;
	align-self: center;
	button {
		border: none;
		outline: none;
		svg {
			fill: #919eab;
		}
	}

	> *:first-child:not(:last-child) {
		opacity: 0;

		&:focus-visible {
			opacity: 1;
		}
	}
}

.report-builder-heading {
	background-color: $background-color;
	align-items: center;
	padding: 18px 20px;
	border-top-left-radius: calc(4px / var(--scale-x, 1));
	border-top-right-radius: calc(4px / var(--scale-x, 1));
	border-bottom: 1px solid #e9e9e9;

	h5 {
		margin-bottom: 0;
		text-align: center;
		vertical-align: middle;
		color: #333;
	}
}

.report-builder-list-heading {
	font-size: 18px;
	line-height: 20px;
	font-weight: 700;
	margin: 0;
	text-align: center;
}

.report-builder-custom-input {
	height: 40px;
	line-height: 1;
	padding: 11px 16px;
	border: 1px solid #d6d6d6;
	border-radius: 8px;
	font-size: 14px;
	color: var(--theme-primary);
	flex: 1 1;
	max-width: 450px !important;

	min-height: 40px !important;
}
.dummy-element {
	height: auto;
}

.scrollable {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	z-index: 999;

	.report-builder-add-custom-header-button.ant-btn.ant-btn-background-ghost {
		margin-top: auto;
		color: var(--dove-gray);
		border-color: var(--dove-gray);

		&:hover {
			color: var(--theme-primary-highlight);
			border-color: var(--theme-primary-highlight);
		}
	}
}

.report-builder-input {
	width: 100%;
}

.report-builder-input-container {
	margin-bottom: 20px;
}

.report-builder-editable {
	font-weight: 600;
	max-width: 250px;
	overflow-wrap: break-word;
}

.tab-list {
	display: flex;
	justify-content: center;
	margin-bottom: 0 !important;
	padding: 22px 22px 11px;
	border-bottom: 0px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.report-builder-setting-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background-color: #fff;
	padding: 22px;
	border: 1px solid #ddd;
	border-radius: 15px;
	margin: 0 0 20px;

	.l2 {
		padding-left: 30px;
	}
	.l3 {
		padding-left: 60px;
	}

	.setting-card-inner {
		max-width: 780px;
		width: 100%;
	}

	.setting-card-title {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin: 30px 0 22px;

		&:first-child {
			margin-top: 0;
		}

		span {
			margin-left: 4px;
			color: #7d7e7f;
			font-size: 12px;
			line-height: 12px;
			font-weight: 600;
		}
	}
	.ant-radio-group {
		margin: 0 0 14px;
	}

	.custom-form-group {
		display: flex;
		align-items: center;

		.custom-label {
			margin: 0;
			margin-right: 10px;
			font-weight: 600;
			min-width: 130px;
		}
		.ant-form-item,
		.custom-input {
			flex: 1;
			max-width: 450px;
		}
		&.ant-form-item {
			.ant-form-item-control-wrapper {
				flex: 1;
			}
		}
		button {
			margin-left: 10px;
		}
	}
}

.report-builder-grid-container {
	display: inline-grid;
	box-sizing: border-box;
	padding: 30px 0 0px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: 1rem;
	overflow: auto;
	max-width: 1024px;

	@media (min-width: 992px) {
		width: 90%;
	}

	.report-builder-trash {
		grid-column: 1 / -1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100px;
		border-radius: 5px;
		border: 5px solid;
		font-size: 20px;
		font-weight: 800;
	}
}

.report-builder-export-settings-container {
	width: 100%;
	max-width: 1024px;

	.setting-heading {
		margin: 0 0 11px;
	}

	.report-builder-export-settings {
		background-color: rgb(246, 246, 246);
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.05);
		padding: 1rem 1rem 0 1rem;

		.row {
			justify-content: center;

			.ant-select {
				max-width: 197px;
			}
		}
	}
}

.setting-card > .row {
	margin-bottom: 0.75rem;
}

.setting-card .row .setting-card-title {
	margin-bottom: 0.75rem;
}

.report-builder-title-with-error {
	display: flex;
	gap: 1rem;
	align-items: baseline;
}

.report-builder-default {
	font-size: 12px;
	font-style: italic;
	font-weight: 600;
	color: var(--oslo-gray);

	.report-builder-default-label {
		font-style: normal;
		color: #333;
		margin-right: 10px;
	}
}
