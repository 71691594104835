.b-red {
	border: 2px solid red;
}

.physical-reward-modal {
	// display: flex;
	// align-items: center;

	.ant-form-item-children {
		justify-content: center;
	}

	.physical-reward-badge {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.award-detalis {
		background-color: #f5f5f5;
		border-radius: 8px;
		padding: 8px;
		margin: 10px 0 0 0;

		.award-detalis-info {
			display: flex;
			// align-items: center;
			font-size: 18px;
			font-weight: 600;

			.award-detalis-about {
				font-size: 16px;

				.award-detalis-name {
					color: var(--black);
				}

				// align-items: center;
				.award-detalis-numbers {
					color: #6f6e70;
				}
			}
		}

		.text-gray {
			color: gray;
		}
	}
    .physical-reward-details-title {
		font-size: 20px;
		font-weight: 700;
		color: var(--black);
	}
	.physical-reward-details-semi-title {
		font-size: 14px;
		font-weight: 700;
		color: var(--black);
        margin: 10px 0 0 0;
	}

	.physical-social-icon {
		display: inline;

		.social-icon {
			object-fit: contain;
			width: 50px;
			height: 50px;
			padding: 0 4px;
		}
	}

	.physical-reward-container {
		width: auto !important;
	}

	.physical-reward-badge {
		margin-bottom: 20px;
		flex-direction: column;
		text-align: center;
	}

	.line-clamp-2 {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
}