.profile-card {
  background-color: #fff;
  padding: 24px 20px;
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 20px;

  .po-details-edit {
    display: flex;
    align-items: center;
    label {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      margin-right: 4px;
      flex: 0 0 auto;
      margin-bottom: 4px;
      min-width: 100px;
    }
    .custom-input {
      & ~ button {
        margin-left: 14px;
      }
    }
    .select-currency {
      width: 100%;
      .react-autosuggest__suggestions-container {
        z-index: 999;
      }
    }
    &.block-details {
      flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
      width: 96px;
      height: 96px;
      margin: 0;
      background-color: #fafdff;
      border: 1px dashed #ddd;
      border-radius: 6px;

      i {
        display: block;
        color: #1d8dd4;
        font-size: 24px;
      }
    }
    &.profile-upload {
      flex-wrap: wrap;
      label {
        width: 100%;
        margin: 0 0 4px;
      }
      .ant-upload-picture-card-wrapper {
        display: flex;
        flex-direction: column;
        
        .ant-upload-list-picture-card {
          margin-top: 10px;
          border-radius: 6px;
          margin-bottom: 10px;

          .ant-upload-list-picture-card-container {
            margin: 0;
            width: 96px;
            height: 96px;

            .ant-upload-list-item {
              width: 96px;
              height: 96px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
      .upload-picture-wrap {
        display: flex;
        align-items: flex-end;

        .cancel-btn {
          color: #f43943;
          cursor: pointer;
          flex: 1 0 auto;
          margin-left: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;

          i {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .profile-details {
    max-width: 600px;
    width: 100%;
    margin-right: 14px;
    .profile-name {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 0 0 20px;
    }
    .profile-email {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 14px;
      i {
        color: #8e99a3;
        margin-right: 10px;
      }
    }
    .profile-other-details {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      margin: 0 0 18px;

      .label {
        font-weight: 700;
        margin-right: 4px;
        min-width: 100px;
        display: inline-block;
      }
    }
    
    .edit-profile-name {
      display: flex;
      justify-content: space-between;

      .custom-form-group {
        width: calc(100% / 2 - 5px);
      }
    }
    .link-wrap  {
      margin: 0 0 20px;
    }
  }
  .profile-actions {
    margin-left: auto;
    .profile-confirmation-btn {
      display: flex;
      flex-direction: column;
      button {
        + button {
          margin-top: 10px;
        }
      }
    }
  }
  .profile-heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 16px;
  }
  .profile-subheading {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin: 0 0 8px;
  }
}
.profile-bottom-links {
  display: flex;
  align-items: center;
  .link-wrap {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    margin-right: 20px;
    .profile-heading {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {
  .profile-card {
    flex-wrap: wrap;
    padding: 18px 14px;

    .profile-details {
      margin-right: 0;

      .po-details-edit {
        flex-wrap: wrap;

        .custom-input {
          ~ button {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
      .profile-other-details {
        .label {
          display: block;
          margin-bottom: 4px;
        }
      }
      .edit-profile-name {
        flex-wrap: wrap;

        .custom-form-group {
          width: 100%;
        }
      }
    }
    .profile-actions {
      order: -1;
      width: 100%;
      margin-bottom: 20px;

      .profile-confirmation-btn {
        flex-direction: row;
        align-items: center;
        width: 100%;

        button {
          width: 100%;
          + button {
            margin-top: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .profile-bottom-links {
    flex-wrap: wrap;
  }
  .notify-btn-grp {
    button {
      + button {
        margin-left: 6px;
      }
    }
  }
}
