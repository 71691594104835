.reward-modal .ant-modal-title {
	text-align: left;
}
.reward-modal {
	.ant-modal-body {
		height: 100%;
		overflow-y: auto;
	}
}
.error-tooltip {
	.ant-tooltip-inner {
		background-color: rgba(0, 0, 0, 0.85);
		color: #fff;
	}

	.ant-tooltip-arrow::before {
		background-color: rgba(0, 0, 0, 0.85);
	}
}
.list-img {
	img {
		width: 24px;
		object-fit: contain;
		height: 24px;
	}
}

.add-edit-reward-loader {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(249, 249, 249, 0.5);
	border-radius: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reward-modal {
	.custom-form-group {
		.custom-label {
			width: 130px;
			margin-bottom: 0;
		}
	}
	.ant-form-item-control-input-content {
		display: flex;
		align-items: center;
	}
}
.permission-detail {
	.custom-form-group {
		.custom-label {
			width: 250px;
		}
	}
}
.asterisk {
	color: red !important;
}

.ant-tooltip {
	.ant-tooltip-inner {
		background-color: rgba(0, 0, 0, 0.85);
		color: #fff;
	}

	.ant-tooltip-arrow::before {
		background-color: rgba(0, 0, 0, 0.85);
	}
}

.reward-modal .custom-form-group .ant-form-item-children {
	align-items: center;
}

.reward-modal .ant-modal-header {
	padding: 20px 20px 8px 20px;
}

.reward-modal .ant-tooltip-content .ant-tooltip-arrow::before {
	background-color: rgba(0, 0, 0, 0.85);
}

.reward-modal .ant-tooltip-content .ant-tooltip-inner {
	background-color: rgba(0, 0, 0, 0.85);
	color: #fff;
}

.custom-selection.css-2b097c-container .css-yk16xz-control {
	height: 40px !important;
}

.custom-selection.css-2b097c-container .css-g1d714-ValueContainer {
	height: 40px;
}

.custom-selection.css-2b097c-container .css-1wa3eu0-placeholder {
	line-height: normal;
}

.award-panel.ant-collapse-borderless > .ant-collapse-item {
	border: none !important;
}

.award-panel.ant-collapse
	> .ant-collapse-item
	> .ant-collapse-header
	.ant-collapse-arrow {
	position: inherit;
	top: inherit;
	left: inherit;
	transform: inherit;
	margin-left: 10px;
}

.award-panel.ant-collapse
	> .ant-collapse-item:last-child
	> .ant-collapse-header {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row-reverse;
	color: #95979a;
}

.collpse-btn {
	cursor: pointer;
	background-color: #ffffff;
	// z-index: 9;
}

.collpse-btn:hover,
.collpse-btn:active,
.collpse-btn:focus {
	background: none !important;
	color: #95979a;
	border-color: transparent !important;
}

.grid-7 {
	grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-5 {
	grid-template-columns: repeat(5, minmax(0, 1fr));
}

.spinner-height {
	min-height: 200px;
	width: 100% !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.add-reward-preview {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	// width: calc(100% - 100px);
}

.reward-svg {
	scale: 1.5;
	margin-top: 100px;
	//height: 80%;
	//display: flex;
	//align-items: center;

	//svg {
	//	width: 166px !important;
	//	height: 144px;
	//}
}

.preview-label {
	margin-top: 64px !important;
}

// .reward-svg {
// 	border: 1px solid red;
// 	position: relative;

// 	.label {
// 		position: absolute;
// 		background-color: rgb(68, 67, 67);
// 		color: white;
// 		font-weight: 600;
// 		width: 128px;
// 		padding: 4px;
// 		border-radius: 4px;
// 		text-align: center;
// 		right: 0;
// 		top: 0;
// 	}
// }
.hello-tabs {
	background-color: red;
	height: 100px;
}

.color-card {
	margin-bottom: 0;
	align-items: center;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 10px;
	text-align: center;
	color: #fff;

	h4 {
		color: #fff;
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		margin: 0 0 5px;
	}
	p {
		color: #fff;
    	font-size: 14px;
    	line-height: 18px;
    	margin: 0;
	}
}

.contact-color-card {
	column-gap: 10px;
	display: flex;
}

.shiny-svg {
	transform: perspective(400px) rotate3d(1, -1, 0, calc(var(--i, 1) * 8deg));
	mask: linear-gradient(135deg, #000c 40%, #000, #000c 60%) 100% 100%/240% 240%;
	transition: 0.4s;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.shiny-svg:hover {
	--i: -1;
	mask-position: 0 0;
}

.search-recognitions {
	.ant-select-selection-search-input {
		height: 38px !important;
	}
}

.select-recipient-search {
	div {
		span {
			input {
				height: 32px;
			}
		}
	}
	.ant-select-selection-search-input {
		height: 32px;
	}
}

.feed-reward-logo {
	margin-top: -2px;
	margin-right: 4px;
}

.bonus-employee-picker {
	> div > span > input.ant-select-selection-search-input {
		height: 36px !important;
	}
}

.badge-shadow {
	width: 130px;
	filter: drop-shadow(0rem 0.35rem 0.4rem rgba(0, 0, 0,0.3));
	transition: filter 0.3s ease, transform 0.3s ease;
}

.badge-ribbon {
	position: absolute;
	bottom: 17%;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, .8) 100%);
	padding: 5px 5px;
	margin: 0 5px;
	min-width: 120px;
	text-align: center;
	border-radius: 15px;
	will-change: transform;
	.badge-text {
		color: #fff;
		font-weight: bold;
		font-size: 11.5px;
		line-height: 1.1;
		text-wrap: pretty;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}
}
.badge-ribbon.badge-hover {
	transform: perspective(1000px) translateZ(25px);
	transition: transform 0.2s ease-in-out;
}
.infinite-scroll-component__outerdiv {
	.infinite-scroll-component {
		overflow: unset !important;

	}
}
.badge-card {
	.ant-card-body {
		padding: 0;
	}
}
.main-slider-container {
	.slider-badges-svg {
		text-align: center;
		.img-label {
			margin-top: 10px;
			color: #1d8627;
			font-size: 14px;
			font-weight: bold;
		}
		//span {
		//	padding: 5px 9px;
		//	background-color: #f4efee;
		//	display: flex;
		//	border-radius: 50px;
		//	margin: 10px 0 0 0;
		//	font-weight: bold;
		//	font-size: 13px;
		//}
	}

	.main-slider-container {
		.slick-track {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.img-label {
			width: 64px;
		}
	}
}

.physical-reward-modal {
	.physical-reward-container {
		width: 100%;
	}
	.physical-reward-badge {
		flex-direction: column;
		text-align: center;
	}
}

.nomination-rewards-list {
	.nominated-badges-container {
		cursor: pointer !important;
	}
	.badges-svg {
		text-align: center !important;
	}
	.slick-next {
		right: 0;
	}

	.badge-slider {
		width: unset !important;
	}

	.slick-prev {
		left: 0 !important;
	}

	.slick-arrow:before,
	.slick-arrow:after {
		background-color: blue;
	}
}

.winner-list-modal {
	.physical-reward-badge {
		padding-left: 0 !important;
	}

	.winner-list {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.ant-modal-body {
		height: auto !important;
	}
}

.reward-winner {
	flex: 2;
	min-width: 474px;
	max-width: 100%;
	margin: 0 auto;
	.badge-list {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		column-gap: 16px;
		row-gap: 16px;

		@media (max-width: 1300px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		@media (max-width: 1100px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@media (max-width: 1024px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		@media (max-width: 480px) {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}
}

.nomination-height {
	height: 250px;
}
