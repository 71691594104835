.Container {
    display: flex;
    flex-direction: column;
    grid-auto-rows: max-content;
    overflow: hidden;
    box-sizing: border-box;
    appearance: none;
    outline: none;
    margin: 0 0 20px;
    min-width: 350px;
    border-radius: 15px;
    min-height: 200px;
    transition: background-color 350ms ease;
    background-color: rgba(246, 246, 246, 1);
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 1em;
  
    ul {
      display: grid;
      gap: 1rem;
      padding: 22px;
      margin: 0;
      grid-template-columns: 1fr 1fr 1fr;
      list-style: none;
    }
  
    &.scrollable {
      ul {
        overflow-y: auto;
      }
    }
  
    &.placeholder {
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
      background-color: transparent;
      border-style: dashed;
      border-color: rgba(0, 0, 0, 0.08);
  
      &:hover {
        border-color: rgba(0, 0, 0, 0.15);
      }
    }
  
    &.hover {
      background-color: rgb(235, 235, 235, 1);
    }
  
    &.unstyled {
      overflow: visible;
      background-color: transparent !important;
      border: none !important;
    }
  
    &.horizontal {
      width: 100%;
  
      ul {
        grid-auto-flow: column;
        grid-auto-columns: max-content;
      }
    }
  
    &.shadow {
      box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
    }
  
    &:focus-visible {
      border-color: transparent;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
    }
  }
  
  .Header {
    display: flex;
    color: var(--black);
    font-size: 14px;
    padding: 5px 18px 0px 0px;
    padding-right: 8px;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	margin: 0 0 10px;
  border: none;
  
    &:hover {
      .Actions > * {
        opacity: 1 !important;
      }
    }
  }
  
  .Actions {
    display: flex;
  
    > *:first-child:not(:last-child) {
      opacity: 0;
  
      &:focus-visible {
        opacity: 1;
      }
    }
  }