.sidebar {
	width: 260px;
	// height: calc(100vh - 12px);
	height: 100vh;
	overflow-y: auto;
	flex: 0 0 auto;
	background-color: var(--menu-color, var(--cello));
	transition: all 0.3s ease;
	position: sticky;
	top: 0px;

	.sidebar-logo {
		padding: 10px 24px 0;
		width: 100%;

		img {
			width: 100%;
			display: block;
			margin: 0 auto;
		}

		.sidebar-logo-label {
			margin-top: 5px;
			max-width: 200px;
		}

		.powered-by-ERIN {
			text-align: center;
		}
	}

	.menu-btn {
		width: 100%;
		color: #fff;
		padding: 24px 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		transition: all 0.3s ease-in;
		border-left: 4px solid transparent;
		border-radius: 0;

		.anticon {
			font-size: 30px;
		}

		span {
			margin-left: 14px !important;
			color: #fff;
			font-size: 18px;
			line-height: 22px;
			font-weight: 400;
		}

		&:hover,
		&.active {
			border-color: var(--menu-highlight-color, #ffffff);
			background-color: rgba(255, 255, 255, 0.1);
		}
	}

	.side-menu {
		margin: 0;
		padding: 0;
		list-style: none;

		> .menu-item {
			.ant-collapse {
				background-color: transparent;
				border: none;

				.ant-collapse-item {
					border: none;
				}

				.ant-collapse-header {
					padding: 0;

					.ant-collapse-arrow {
						color: #ffffff;
					}
				}

				.ant-collapse-content {
					border: none;
					background-color: transparent;
				}
			}

			> a,
			.menu-link {
				padding: 24px 16px 24px 30px;
				display: flex;
				align-items: center;
				border-left: 4px solid transparent;
				transition: all 0.3s ease-in;
				position: relative;
				z-index: 1;

				&:hover,
				&.active {
					border-color: var(--menu-highlight-color, #ffffff);
					background-color: rgba(255, 255, 255, 0.1);
				}

				.menu-icon {
					margin: 0 14px 0 0;
				}

				.menu-title {
					color: #fff;
					font-size: 18px;
					line-height: 22px;
					font-weight: 400;
					flex: 1;
				}
			}

			.sub-menu {
				margin: 0;
				padding: 0 0 0 60px;
				list-style: none;

				.sub-menu-item {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:not(:last-child) {
						margin: 0 0 16px;
					}

					.sub-menu-link {
						font-size: 14px;
						line-height: 20px;
						font-weight: 400;
						color: #fff;
						display: block;
						border-left: 4px solid transparent;
						transition: all 0.3s ease-in;
						padding-left: 8px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						&:hover,
						&.active {
							border-color: var(--menu-highlight-color, #ffffff);
						}
					}

					svg {
						opacity: 0.5;
						padding: 4px;
						box-sizing: content-box;
					}

					.tooltiptext {
						a,
						a:hover {
							color: #fff;
							text-decoration: underline;
						}
					}
				}
			}

			.lock {
				opacity: 0.5;
			}
		}
	}

	.sidebar-switch-wrap {
		margin: 80px 0 30px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.sidebar-switch-container {
		display: grid;
		gap: 0.1rem;
		padding: 0.1rem;
		grid-template-columns: minmax(0, 1fr) auto minmax(0, 1.2fr);
		justify-items: center;
		text-align: center;
		white-space: nowrap;
		width: 100%;
		margin-bottom: 30px;

		.switch-label {
			font-size: 14px;
			color: #000;
			opacity: 0.5;

			&.active {
				font-weight: 700;
				opacity: 1;
			}
		}
	}

	.sidebar-info {
		padding: 16px;
		font-size: 16px;
		text-align: center;
		color: var(--oslo-gray);

		a {
			color: #fff;
			font-weight: 700;
			margin: 0 4px;
		}
	}
}

.menu-tooltip {
	position: relative;
	display: inline-block;

	.tooltiptext {
		visibility: hidden;
		width: 220px;
		background-color: #9d9696;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 4px;
		position: absolute;
		z-index: 1;
		top: 50%;
		left: calc(100% + 4px);
		transform: translateY(-50%);
		/* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
		opacity: 0;
		transition: opacity 1s;

		&::before {
			content: ' ';
			position: absolute;
			top: 50%;
			right: 100%;
			/* To the right of the tooltip */
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent #9d9696 transparent transparent;
		}
	}

	&:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
		z-index: 99999;
	}
}

.menu-item {
	.ant-collapse-content {
		overflow: visible;
	}
}

@media (max-width: 991px) {
	.sidebar {
		&.sidebar-close {
			margin-left: -260px;
		}
	}
}

@media (max-width: 767px) {
	.sidebar {
		.side-menu {
			> .menu-item {
				> a,
				.menu-link {
					padding: 15px;

					.menu-title {
						font-size: 16px;
					}
				}

				.sub-menu {
					padding: 0 0 0 36px;

					.sub-menu-item {
						.sub-menu-link {
							font-size: 14px;
						}

						&:not(:last-child) {
							margin: 0 0 12px;
						}
					}
				}
			}
		}
	}
}
