.physical-reward-table {
    .pending-text {
        color: #F7A34D;
    }

    .confirmed-text {
        color: #1D8627;
    }

    .Expired-text {
        color: #F43943;
    }

    .user-name {
        color: #0686D8;
        font-weight: 550;
        font-size: 14px;
    }

    .ant-table-wrapper {
        background: white;
        border-radius: 10px;
       
        .ant-table {
            border-radius: 10px;
        }

        .ant-table-thead tr th {
            background-color: white;
        }

        .ant-table-column-title {
            font-weight: 700;
            // font-size: 16px;
        }
    }
}