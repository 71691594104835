.feed-container{
    .feed-reward-logo{

            height: 42px;
            width: 42px;
            position: relative;
            top: -12px;
            right: 12px;
            transform: scale(.25);
            transition: transform .3s;
            &:hover {
                transform: scale(.5);
            }
    }
    .clap-icon{
        svg{
            fill: red
        }
    }
}