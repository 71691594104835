.bonus-collapse {
	background-color: transparent;

	.bonus-collapse-card {
		background-color: #fff;
		border: 1px solid #dddddd;
		border-radius: 10px;
		margin-bottom: 10px;

		&:last-child {
			border-radius: 10px;
		}

		.ant-collapse-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px 22px;
			.ant-collapse-arrow {
				display: none;
			}
			h4 {
				font-size: 16px;
				line-height: 20px;
				font-weight: 700;
				margin: 0;
				margin-right: 15px;
			}
			.ant-collapse-extra {
				transition: all 0.3s ease;
				.bonus-collapse-icon-wrap {
					display: inline-block;
					transition: all 0.3s ease;
				}
			}
			.bonus-collapse-icon {
				font-size: 8px;
			}
			& + .ant-collapse-content {
				.ant-collapse-content-box {
					padding-top: 0;
				}
			}
			.ac-header-right {
				display: inline-block;
				align-items: center;
				.header-right-text {
					color: #8d99a3;
					font-size: 14px;
					line-height: 14px;
					font-weight: 600;
					margin-right: 10px;
					&.active {
						color: #00641f;
					}
				}
			}
		}
		&.ant-collapse-item-active {
			.ant-collapse-extra {
				.bonus-collapse-icon-wrap {
					transform: rotate(-180deg);
				}
			}
		}
		.ant-collapse-content-box {
			padding: 24px 22px;
		}
	}
}
.bonus-rule-actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 0 0 10px;

	.ant-btn {
		&:not(:last-child) {
			margin-right: 8px;
		}
	}
}
.bs-form {
	.bs-form-label {
		font-size: 14px;
		line-height: 16px;
		font-weight: 700;
		margin-bottom: 10px;
		color: #000;
		.required {
			color: #fd6266;
		}
	}
	.bs-form-label-eligibility {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin-bottom: 12px;
		padding-top: 10px;
		color: #000;
		.required {
			color: #fd6266;
		}
	}
	.custom-form-group {
		.custom-input {
			background-color: #fafdff;
			border-color: #c9c9c9;
			max-width: 194px;
			color: #000;

			&.ant-select {
				background: transparent;
				border: 0;
				padding: 0;
			}
			.ant-select-selector {
				background-color: #fafdff;
				border-color: #c9c9c9;
				.ant-select-arrow {
					right: 8px;
				}
			}
			&.ant-input-number {
				min-width: auto;
				max-width: 90px;
				padding: 0;
				.ant-input-number-input {
					padding: 13px 16px;
					height: auto;
				}
			}
			&.ant-input-number-focused {
				& + .cf-addon {
					box-shadow: 0 0 0 2px rgb(24, 144, 255, 0.2);
				}
			}
			&.ant-calendar-picker {
				padding: 0;
				.ant-calendar-picker-input {
					background: #fafdff;
					.ant-calendar-range-picker-separator {
						vertical-align: middle;
					}
				}
			}
		}
		.cf-addon-wrap {
			.cf-addon {
				background-color: #fafdff;
				border-color: #c9c9c9;
			}
		}
	}
	.ant-btn-primary {
		margin-bottom: 20px;
	}
	.bs-form-collapse {
		background-color: transparent;
		.bs-form-collapse-card {
			border: 0;
			padding-left: 66px;
			.ant-collapse-header {
				padding: 0;
				justify-content: flex-start;
			}
			.bs-form-collapse-icon {
				font-size: 8px;
				transition: all 0.3s ease;
				margin-right: 12px;
			}
			.ant-collapse-content-box {
				padding: 0;
				padding-left: 24px;
			}
			&.ant-collapse-item-active {
				.bs-form-collapse-icon {
					transform: rotate(180deg);
				}
			}
		}
	}
}
.bs-form-grp {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 0 20px;
	.custom-form-group {
		display: flex;
		align-items: center;
		margin-right: 15px;
		margin-bottom: 0;
		.custom-input {
			min-width: 194px;
		}
		.bs-label {
			font-size: 12px;
			white-space: nowrap;
			font-weight: 700;
			margin-right: 6px;
			margin-bottom: 0;
			color: #000;

			&.bs-label-width {
				min-width: 90px;
			}

			&.bs-label-nobold {
				font-weight: 100;
			}
		}
	}
}
.bs-form-grp-eligibility {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 0 5px;
	.custom-form-group {
		display: flex;
		align-items: center;
		margin-right: 15px;
		margin-bottom: 0;
		.custom-input {
			min-width: 194px;
		}
		.bs-label {
			font-size: 12px;
			white-space: nowrap;
			font-weight: 700;
			margin-left: 6px;
			margin-bottom: 0;
			color: #000;

			&.bs-label-width {
				min-width: 90px;
			}
		}
		.bs-label-stage {
			font-size: 12px;
			white-space: nowrap;
			margin-left: 6px;
			margin-bottom: 0;
			color: #000;
			padding-right: 12px;

			&.bs-label-width {
				min-width: 90px;
			}
		}
	}
}
.eligibility-explanation {
	margin-left: 20px;
	margin-bottom: 12px;
}
.bonus-heading {
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	margin: 38px 0 10px;
}
.jobs-selected-wrap {
	display: flex;
	align-items: center;
	margin-top: -6px;
	margin-bottom: 12px;

	.jobs-selected {
		display: inline-flex;
		background-color: #6e86b3;
		padding: 6px 12px;
		margin-right: 16px;
		border-radius: 8px;
		font-size: 14px;
		line-height: 16px;
		color: #fff;
	}
}
.create-bonus-campaigns {
	padding: 24px 22px;
	border-radius: 10px;
	background-color: #fff;
	border: 1px solid #dddddd;

	.campaign-title {
		font-size: 20px;
		line-height: 20px;
		font-weight: 700;
		margin: 0 0 12px;
		color: #000;

		& + .campaign-subtitle {
			margin-top: -8px;
		}
	}
	.campaign-subtitle {
		font-size: 13px;
		line-height: 16px;
		margin-bottom: 12px;
		color: #000;
	}
	.campaign-form-wrap {
		display: flex;
		flex-wrap: wrap;

		.bs-form {
			flex: 1 1 auto;
			max-width: 500px;
			margin-right: auto;
			.custom-input {
				max-width: 480px;
			}
		}
		.job-selected {
			margin-bottom: 20px;
			.download-link {
				text-align: center;
				font-size: 12px;
				line-height: 12px;
				font-weight: 600;
			}
		}
	}
}
.company-balance {
	font-size: 20px;
	line-height: 22px;
	color: #000;
	margin: 0 0 12px;

	span {
		margin-left: 2px;
	}
}
.point-to-currency {
	display: flex;
	align-items: center;

	p {
		margin: 0 8px 0 0;
		color: #000;
		font-weight: 600;

		span {
			font-size: 85%;
			display: block;
			color: var(--oslo-gray);
		}
	}

	& > span {
		font-size: 16px;
		line-height: 20px;
		color: #000;
	}
}
.point-links {
	margin: 30px 0 20px;

	.ant-btn-link {
		margin-right: 16px;
	}
}
.points-inline {
	display: flex;
	align-items: center;

	.custom-label {
		margin: 0;
		color: #000;
	}
	& > span {
		color: #000;
		margin: 0 4px 0 12px;
	}
}
.bp-divider {
	border-bottom: 1px solid #d1d1d1;
	max-width: 490px;
	margin: 0 0 14px;
}
.points-card {
	background-color: #fff;
	padding: 22px;
	border: 1px solid #ddd;
	border-radius: 12px;
	margin: 0 0 30px;
}
.bonus-points-column {
	display: flex;
	justify-content: space-between;
	.bonus-points {
		flex: 1 0 auto;
		max-width: calc(50% - 15px);
	}
}
.bonus-points {
	background-color: #fff;
	padding: 22px;
	border: 1px solid #ddd;
	border-radius: 12px;
	margin: 0 0 30px;

	.bp-form-grp {
		display: flex;
		align-items: center;
		margin: 0 0 16px;

		.custom-form-group {
			margin: 0;
			label {
				margin: 0;
			}
			&.custom-checkbox {
				font-weight: 600;
			}
			& ~ button {
				margin-left: 14px;
			}
		}
	}
	.bp-heading {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		margin: 0 0 14px;
	}
	.bp-subheading {
		color: #8e9aa3;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		margin: 0 0 20px;
	}
	.bp-body {
		.ant-form-item {
			margin: 0;
		}
		.custom-form-group {
			display: flex;
			align-items: center;
			margin: 0 0 14px;

			label {
				font-size: 14px;
				line-height: 14px;
				margin: 0;
				min-width: 190px;
				color: #000;
				font-weight: 700;
				display: inline-flex;

				i,
				.anticon {
					color: var(--theme-primary);
					margin-left: 4px;
				}
			}
			.custom-input {
				max-width: 100px;
				background-color: #fafdff;
				margin-right: 14px;

				+ span {
					font-size: 12px;
					line-height: 12px;
					color: #000;
				}
			}
		}
	}
}
.log-box {
	background-color: #fafdff;
	border: 1px solid #c9c9c9;
	border-radius: 10px;
	padding: 10px;
	max-height: 250px;
	overflow-y: auto;

	p {
		font-size: 12px;
		line-height: 16px;
		margin: 0 0 6px;
		color: #494949;
	}
}
.bonus-campaigns {
	.campaign-details {
		display: flex;
		.campaign-left {
			flex: 1 1 auto;
			margin-right: 14px;
			.campaign-tag {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin-bottom: 14px;

				.tag-label {
					font-size: 14px;
					line-height: 16px;
					font-weight: 700;
					margin-right: 10px;
					margin-bottom: 5px;
					color: #000;
					min-width: 182px;
				}
			}
			.custom-form-group {
				display: flex;
				align-items: center;
				margin: 0 0 14px;

				.ant-form-item {
					margin: 0;
					width: 100%;
				}
				label {
					font-size: 14px;
					line-height: 16px;
					font-weight: 700;
					margin-right: 10px;
					margin-bottom: 0;
					color: #000;
					min-width: 182px;
				}
				.custom-input {
					max-width: 480px;
				}
			}
			.cl-details {
				font-size: 14px;
				line-height: 16px;
				font-weight: 400;
				margin-right: 10px;
				margin-bottom: 14px;

				&.secondary {
					color: #6e86b3;
				}

				span {
					font-weight: 700;
					margin-right: 10px;
					color: #000;
					min-width: 182px;
					display: inline-block;
				}
			}
		}
		.profile-actions {
			.campaign-confirmation-btn {
				.ant-btn {
					margin-bottom: 8px;
				}
			}
		}
	}
}
.paragraph-info-icon {
	display: flex;
	align-items: center;

	i,
	.anticon {
		margin-left: 6px;
		cursor: pointer;
	}
}

@media (max-width: 767px) {
	.bonus-points {
		padding: 16px;

		.bp-form-grp {
			flex-wrap: wrap;

			.custom-form-group {
				& ~ button {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}
		.bp-body {
			.custom-form-group {
				flex-wrap: wrap;

				label {
					margin: 0 0 4px;
				}
				.custom-input {
					max-width: calc(100% - 44px);
					margin-right: 10px;
				}
			}
		}
	}
	.bonus-logs {
		padding: 16px;
	}
	.bonus-collapse {
		.bonus-collapse-card {
			.ant-collapse-header {
				padding: 18px 16px;
			}
			.ant-collapse-content-box {
				padding: 18px 16px;
			}
		}
	}
	.create-bonus-campaigns {
		padding: 18px 16px;
	}
	.bonus-campaigns {
		.campaign-details {
			flex-wrap: wrap;
			.campaign-left {
				flex: 0 0 100%;
				margin-right: 0;
				order: 2;

				.custom-form-group {
					flex-wrap: wrap;

					label {
						margin-right: 0;
						margin-bottom: 4px;
						min-width: 100%;
					}
				}
				.campaign-tag {
					.tag-label {
						margin-right: 0;
						margin-bottom: 6px;
						min-width: 100%;
					}
				}
				.cl-details {
					span {
						margin-right: 0;
						min-width: 100%;
						display: block;
						margin-bottom: 6px;
					}
				}
			}
			.profile-actions {
				flex: 0 0 100%;
				margin-bottom: 14px;
			}
		}
	}
	.campaign-confirmation-btn {
		flex-direction: row;
		align-items: center;

		button {
			+ button {
				margin-top: 0;
				margin-left: 10px;
			}
		}
	}
	.bonus-rule-actions {
		flex-wrap: wrap;

		.ant-btn {
			width: 48%;
			&:first-child {
				width: 100%;
				margin-right: 0;
				margin-bottom: 8px;
			}
		}
	}
}
