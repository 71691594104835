//Create a New Job Styles
.amount-number {
  display: flex;
  align-items: center;

  > span {
    font-size: 14px;
    line-height: 16px;
    color: #7f7f7f;
    margin-right: 8px;
  }
  .ant-input-number {
    max-width: 140px;
  }
}
.c-form-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .c-form-col:not(:last-child) {
    margin-right: 12px;
  }
  .divider-text-row {
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }
}
.modal-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  &.space-between {
    justify-content: space-between;
  }

  .ant-btn:not(:last-child) {
    margin-right: 16px;
  }
  &.ant-form-item {
    .ant-form-item-control-wrapper {
      flex: 1;

      .ant-form-item-control {
        line-height: 1;
      }
      .ant-form-item-children {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.label-data {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  > li {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    label {
      color: #000;
      font-weight: 700;
      margin: 0 8px 0 0;
      display: inline-flex;

      &.label-block {
        width: 100%;
        display: block;
        margin: 0 0 10px;
      }
    }

    .custom-form-group {
      margin: 0;
    }
    .ant-calendar-picker {
      width: 180px;
    }
  }
}

.bonus-summary-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 8px;

    span:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.referral-modal {
  .ant-modal-content {
    background: #fafdff;
  }
}
.referral-select-job-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;

  p,
  .search-input {
    margin: 0;
  }
}
.send-by {
  display: flex;

  .custom-form-group {
    flex: 1;
  }
}
.select-date {
  padding: 16px;
  border-radius: 16px;
  background: #bdf9bd;
  margin-bottom: 16px;
  text-align: center;
}
//Bonus Detail Modal
