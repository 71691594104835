.setting-heading {
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	margin: 0 0 10px;
}
.setting-card {
	background-color: #fff;
	padding: 22px;
	border: 1px solid #ddd;
	border-radius: 15px;
	margin: 0 0 20px;

	.l2 {
		padding-left: 30px;
	}
	.l3 {
		padding-left: 60px;
	}

	.setting-card-inner {
		max-width: 780px;
		width: 100%;
	}

	.setting-card-title {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin: 30px 0 22px;

		&:first-child {
			margin-top: 0;
		}

		span {
			margin-left: 4px;
			color: #7d7e7f;
			font-size: 12px;
			line-height: 12px;
			font-weight: 600;
		}
	}
	.ant-radio-group {
		margin: 0 0 14px;
	}
	.setting-card-editor {
		margin: 0 0 20px;

		.DraftEditor-root {
			max-height: 320px;
			overflow-y: auto;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: 'Nunito Sans', sans-serif;
				font-weight: 700;
				margin: 0 0 14px;
				div {
					margin: 0;
				}
			}
			span {
				font-family: 'Nunito Sans', sans-serif;
			}
		}
	}
	.setting-form-inline {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 0 14px;

		.custom-form-group {
			margin: 0;
			label {
				margin: 0;
			}
			&:not(:last-child) {
				margin-right: 14px;
			}
			&:not(.custom-checkbox) {
				flex: 1;

				.custom-input,
				.ant-form-item-control-wrapper {
					flex: 1;
				}
			}
			.custom-input {
				width: 100%;
			}
		}
	}
	.custom-form-group-note {
		font-size: 12px;
		line-height: 14px;
		font-weight: 600;
		margin: 0;
		font-style: italic;
	}
	.custom-form-group {
		display: flex;
		align-items: center;

		.custom-label {
			margin: 0;
			margin-right: 10px;
			font-weight: 600;
			min-width: 130px;
		}
		.ant-form-item,
		.ant-legacy-form-item,
		.custom-input {
			flex: 1;
			max-width: 450px;
		}
		&.ant-form-item {
			.ant-form-item-control-wrapper {
				flex: 1;
			}
		}
		button {
			margin-left: 10px;
		}
	}

	.custom-form-group-with-errors {
		display: flex;
		align-items: flex-start;

		.custom-label {
			margin: 0;
			margin-right: 10px;
			font-weight: 600;
			min-width: 130px;
		}
		.ant-form-item,
		.ant-legacy-form-item,
		.custom-input {
			flex: 1;
			max-width: 450px;
		}
		&.ant-form-item {
			.ant-form-item-control-wrapper {
				flex: 1;
			}
		}
		button {
			margin-left: 10px;
		}
	}
	.setting-card-collaspe {
		background-color: #fff;
		display: inline-flex;
		margin: 0 0 18px;
	}
}
.sync-btn-grp {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 0 10px;

	.ant-btn {
		margin-bottom: 10px;

		&:not(:nth-last-child(1)) {
			margin-right: 10px;
		}
	}
}
.employee-dashboard-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
	padding: 12px;

	.ed-row {
		display: flex;
		align-items: center;
		padding: 10px;
		background-color: #fff;
		flex-wrap: wrap;
		border-radius: 12px;
		position: relative;

		&:nth-last-child(2) {
			margin-top: 30px;

			&::before {
				content: '';
				top: -20px;
				left: 0;
				width: 100%;
				position: absolute;
				border-top: 2px solid #ddd;
			}
		}

		&.bg-gray {
			background-color: #ebebeb;
		}
	}
	.ed-col {
		width: calc(100% / 3 - 6px);
		user-select: none;
		background: #fff;
		padding: 8px;
		border-radius: 8px;

		+ .ed-col {
			margin-left: 8px;
		}
		.ed-title {
			font-size: 16px;
			line-height: 16px;
			font-weight: 700;
			margin: 0 0 16px;
		}
		.ed-image {
			width: 100%;
			position: relative;

			&::before {
				content: '';
				display: block;
				padding-bottom: 53.44%;
			}
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 767px) {
	.setting-card {
		padding: 18px 16px;

		.setting-form-inline {
			flex-wrap: wrap;

			.custom-form-group {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 14px;
				}
				&:not(.custom-checkbox) {
					flex: 0 0 100%;
				}
			}
		}
		.custom-form-group {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 0 14px;

			.custom-label {
				margin-right: 0;
				margin-bottom: 6px;
			}

			.ant-form-item,
			.ant-legacy-form-item,
			.custom-input {
				flex: 0 0 100%;
				max-width: 100%;
			}
			&.custom-checkbox {
				.custom-label {
					margin: 0;
				}
			}
			button {
				margin-left: 0;
				margin-top: 10px;
			}
		}

		.custom-form-group-with-errors {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin: 0 0 14px;

			.custom-label {
				margin-right: 0;
				margin-bottom: 6px;
			}

			.ant-form-item,
			.ant-legacy-form-item,
			.custom-input {
				flex: 0 0 100%;
				max-width: 100%;
			}
			&.custom-checkbox {
				.custom-label {
					margin: 0;
				}
			}
			button {
				margin-left: 0;
				margin-top: 10px;
			}
		}

		.setting-card-title {
			font-size: 14px;
			line-height: 18px;
		}
	}
	.employee-dashboard-card {
		padding: 10px;
		.ed-col {
			width: 100%;
			padding: 6px;
			margin-bottom: 6px;

			.ed-title {
				margin: 0 0 8px;
			}

			+ .ed-col {
				margin-left: 0;
			}
		}
	}
}
