.contact-card-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.contact-card {
		width: calc(100% / 2 - 12px);
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		.contact-card-title {
			font-size: 16px;
			line-height: 16px;
			font-weight: 700;
			margin: 0 0 10px;
		}
		.contact-card-inner {
			background-color: #fff;
			border: 1px solid #dddddd;
			border-radius: 10px;
			padding: 20px;
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}
}
.referral-request {
	.ant-list-empty-text {
		padding: 0;
	}
	.empty-referrals {
		display: flex;
		flex-direction: column;
		align-items: center;
		[class*='icon-'] {
			color: var(--oslo-gray);
			font-size: 25px;
			margin: 0 0 10px;
		}
		p {
			font-size: 12px;
			line-height: 16px;
			color: #000;
		}
	}
	.ant-list-pagination {
		margin-top: 20px;
		text-align: center;
	}
	.ant-spin-container {
		overflow-x: auto;
	}
	.ant-list-items {
		display: table;
		width: 100%;

		.ant-list-item {
			padding: 0;

			.referral-request-data {
				display: table;
				white-space: nowrap;
				width: 100%;
				padding: 10px 6px;

				&:hover {
					box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
				}

				> span {
					padding-right: 14px;
					display: table-cell;
				}

				.rr-name {
					width: 120px;
					font-weight: 700;
				}
				.rr-position {
					width: 280px;
					font-weight: 700;
				}
				.rr-info {
					display: table-cell;
					margin-left: auto;

					button {
						margin-left: 8px;
					}
				}
			}
		}
	}
}
.invite-qr-code {
	width: 160px;
	display: block;
	margin: auto;
}
.contact-invite-link {
	.invite-label {
		font-size: 14px;
		line-height: 14px;
		margin: 0 0 4px;

		p {
			font-size: 12px;
			line-height: 14px;
			margin: 0 0 8px;
			color: #a8a8a8;
		}
	}
	.invite-search {
		margin: 0 0 30px;
		.ant-input-group {
			display: flex;
			.ant-input {
				flex: 1 1 auto;
				padding: 0 10px;
				font-size: 12px;
				color: #000;
				height: auto;
				line-height: 12px;
				border-radius: 8px 0 0 8px;
			}
			.ant-input-group-addon {
				width: auto;
				.ant-btn {
					margin: 0;
					min-width: 100px;
					font-size: 12px;
					font-weight: 700;
					padding: 10px 10px;
					border-radius: 0 8px 8px 0;
				}
			}
		}
	}
	.invite-link-bottom {
		.invite-by {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			line-height: 14px;
			color: #000;
			margin: 0 0 5px;

			img {
				width: 18px;
				margin-right: 5px;
			}
		}
		p {
			color: #a8a8a8;
			font-size: 12px;
			line-height: 14px;
			margin: 0;
			text-align: center;
		}
	}
}

@media (max-width: 991px) {
	.contact-card-wrap {
		.contact-card {
			width: 100%;
		}
	}
}
