.manage-nominated-reward-modal {
	top: 100px !important;

	.reward-modal .ant-modal-header {
		padding: 0px !important;
	}

	.ant-modal-body {
		height: auto !important;
	}

	.custom-form-group {
		margin: 0 0 24px;

		.custom-label {
			width: 160px !important;
		}

		.ant-calendar-picker {
			width: 100%;

			.ant-calendar-picker-input {
				height: 30px;
			}
		}

		.reward-select-input{
			line-height: 0!important;
			.ant-typography-danger{
    			line-height: 1.5rem !important;

			}
			.custom-input{
				line-height: 0!important;
			}
		}
	}

	.ant-checkbox {
		padding: 10px;
	}

	.choose-reward-input {
		width: 100% !important;
		&.reward-activity-input {
			.ant-select-selection {
				border-radius: 50px;
			}
		}
	}

	.visibility-hidden {
		visibility: hidden;
	}

	.submit-button {
		justify-content: center;
		padding: 10px 14px 0 14px;
	}

	.ant-form-item-control {
		line-height: 20px !important;
	}

	.edit-modal-loader {
		position: absolute;
		background: rgba(255, 255, 255, 0.7);
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		border-radius: 25px;
	}
}

@media (max-width: 1440px) {
	.manage-nominated-reward-modal {
		top: 5% !important;
	}
}