.announcements-card {
  .announcements-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h3 {
      font-size: 16px;
      font-weight: 700;
      margin-right: 15px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .announcements-time {
      color: #838383;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .announcements-body {
    display: flex;
    align-items: flex-start;
    .announcements-content-wrap {
      margin-right: 5px;
      .announcements-group {
        display: flex;
        margin-bottom: 14px;
        .announcements-text {
          font-size: 16px;
          line-height: 30px;
          font-weight: 700;
          white-space: nowrap;
          margin-right: 5px;
        }
      }
      .announcements-content {
        font-size: 14px;
        line-height: 19px;

        .view-more {
          display: inline-flex;
          align-items: center;
          color: var(--theme-primary);
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          cursor: pointer;
          .view-more-icon {
            margin-left: 6px;
            font-size: 8px;
          }
        }
      }
    }
    .announcements-action {
      margin-left: auto;
      display: flex;
      .btn-link {
        margin: 0 5px;
        padding: 5px;
        height: auto;

        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}
.announcements-banner {
  position: relative;
  width: calc(100% + 30px);
  min-height: 258px;
  margin-top: -16px;
  margin-bottom: 22px;
  margin-left: -15px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }
  .announcements-banner-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
  .announcements-banner-edit {
    z-index: 2;
    position: relative;
    text-align: right;
    padding: 25px 25px 6px;
  }
  .announcements-banner-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    padding: 0 111px 30px 30px;

    .left {
      flex: 1;
      margin-right: 15px;
      p {
        font-size: 20px;
        list-style: 25px;
        color: #ffffff;
        margin: 0 0 18px;
        strong {
          font-weight: 900;
          display: block;
        }
      }
    }
    .right {
      p {
        color: #ffffff;
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 18px;
        strong {
          font-weight: 900;
          font-size: 20px;
          display: block;
        }
      }
      .announcements-update-btn {
        font-size: 17px;
        line-height: 24px;
        background: transparent;
        border: 0;
        height: auto;
        color: #fff;
        font-weight: 600;
        padding: 8px 20px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border: 3px solid;
          border-image-source: linear-gradient(to left, #3076bb, #ee3b40);
          border-image-slice: 1;
        }
        &:hover,
        &:focus,
        &:active {
          background-image: linear-gradient(to left, #3076bb, #ee3b40);
        }
      }
    }
  }
}
.announcements-search {
  position: relative;
  max-width: 400px;
  width: 100%;

  input[type='text'] {
    height: auto;
    padding: 9px 38px 9px 14px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #dddddd;
  }
  .announcements-search-icon {
    button {
      position: absolute;
      background-color: #6e86b3;
      width: 32px;
      height: 30px;
      right: 3px;
      top: 3px;
      border-radius: 0 5px 5px 0;
      text-align: center;
      line-height: 15px;
      color: #fff;
      font-size: 19px;
      padding: 0;
    }
  }
}
.announcements-box-content-wrap {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
  padding: 16px 20px;
  max-height: 195px;
  min-height: 195px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  .edit-btn {
    margin-left: auto;
    margin-bottom: 20px;

    button {
      padding: 0;
    }
  }
  .announcements-box-content {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .announcements-no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    img {
      margin: 0 0 10px;
    }
  }
}
.announcements-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h3 {
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-right: 10px;
    font-weight: 900;
  }
  .btn-link {
    [class*='icon-'] {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
.announcements-recommended {
  display: flex;
  flex-direction: column;
  .ant-list-pagination {
    margin: 0 10px;
    order: 1;
  }
  .ant-pagination-item {
    display: none;
  }
  .announcements-recommended-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.announcements-application {
  .ant-list-pagination {
    margin: 0 10px;
  }
  .ant-pagination-item {
    display: none;
  }
  .announcements-application-col {
    .announcements-application-card {
      border: 1px solid #dddddd;
      padding: 0;
      border-radius: 15px;
      background-color: #fff;
      margin: 0;
      margin-bottom: 15px;
      width: 100%;
      overflow: hidden;
      min-height: auto;
      &.my-progressbar-card {
        padding: 18px 20px;
        min-height: 195px;
      }
    }
  }
}
.announcements-more-jobs {
  border: 1px solid #dddddd;
  border-radius: 15px;
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
  padding: 18px 20px;
  background-color: var(--theme-primary);
  text-align: center;
  min-height: 195px;
  h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;
    margin: 0 0 15px;
    color: #fff;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 20px;
    color: #fff;
  }
}
.announcements-jobs {
  border: 1px solid #dddddd;
  border-radius: 15px;
  background-color: #fff;
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
  padding: 18px 20px;
  min-height: 195px;
  .announcements-jobs-title {
    font-size: 16px;
    line-height: 16px;
    color: #262626;
    font-weight: 900;
    margin: 0 0 18px;
    display: inline-block;
  }
  .announcements-jobs-description {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0 0 18px;
  }
  .jobs-description {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 18px;
    }
    i {
      color: #afb1b3;
      margin-right: 4px;
      font-size: 18px;
    }
    .jobs-description-text {
      font-size: 14px;
      line-height: 14px;
      color: #000;
    }
  }
  .btn-white {
    border-color: #cccccc;
    font-family: 'Nunito Sans', sans-serif;
  }
}
.my-applications {
  .ant-row {
    display: flex;
    flex-wrap: wrap;

    .ant-col {
      margin-bottom: 15px;

      .ant-list-item {
        height: 100%;
        margin: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .announcements-card {
    .announcements-head {
      flex-wrap: wrap;
    }
    .announcements-body {
      flex-wrap: wrap;

      .announcements-content-wrap {
        margin-right: 0;
        flex: 0 0 100%;
        order: 1;

        .announcements-group {
          flex-wrap: wrap;
        }
      }
      .announcements-action {
        margin-bottom: 10px;
      }
    }
  }
  .announcements-banner {
    .announcements-banner-content {
      padding: 5px 30px 30px 30px;
    }
  }
}

@media (max-width: 767px) {
  .announcements-banner {
    .announcements-banner-edit {
      padding: 15px 0 6px;
    }
    .announcements-banner-content {
      padding: 5px 15px 30px;
      flex-wrap: wrap;
      .left {
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
      .right {
        flex: 0 0 100%;
      }
    }
  }
}
