.referrals-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 10px;
	.search-input {
		margin-bottom: 10px;
	}
	.referrals-btn-grp {
		margin-bottom: 10px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
}

.my-progressbar {
	.ant-row {
		display: flex;
		flex-wrap: wrap;

		.ant-col {
			margin-bottom: 15px;
			> .ant-list-item {
				height: 100%;
				margin: 0;
			}
		}
	}
	.ant-list-pagination {
		text-align: center;
	}
}
.my-progressbar-card {
	background-color: #fff;
	border: 1px solid #dddddd;
	padding: 16px;
	border-radius: 10px;
	height: 225px;
	width: 100%;

	.ant-card-body {
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	.my-progressbar-top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		flex: 1;
		flex-wrap: nowrap;
		height: 20px;

		.mp-left {
			margin-right: 15px;
			max-width: 48%;
			.my-progressbar-name {
				font-size: 16px;
				line-height: 16px;
				color: #262626;
				margin-bottom: 12px;
				font-weight: 700;
				display: inline-block;
			}
			.my-progressbar-pending-acceptance {
				font-size: 16px;
				line-height: 16px;
				color: var(--oslo-gray);
				margin-bottom: 12px;
				font-weight: 700;
				display: inline-block;
			}
			.my-progressbar-date {
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 6px;
				font-weight: 400;
				display: inline-flex;
				align-items: center;
				color: var(--oslo-gray);

				i {
					color: var(--oslo-gray);
					margin-right: 6px;
				}
			}
			.my-progressbar-social-icons {
				display: flex;
				align-items: center;
				margin: 0 0 5px;

				> a {
					color: var(--theme-primary);
					font-size: 14px;
					line-height: 14px;

					+ a {
						margin-left: 8px;
					}
					+ i {
						margin-left: 8px;
					}
				}
				> i {
					+ i {
						margin-left: 8px;
					}
					+ a {
						margin-left: 8px;
					}
				}
			}
		}
		.mp-right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;
			max-width: 48%;
			a {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 10px;
				font-weight: 700;
				display: inline-block;
			}
			.my-progressbar-date {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 6px;
				font-weight: 400;
				display: inline-flex;
				align-items: center;
				color: var(--oslo-gray);

				i {
					color: var(--oslo-gray);
					margin-right: 6px;
				}
			}
			.my-progressbar-bonus-price {
				font-size: 14px;
				line-height: 19px;
				font-weight: 700;
				color: var(--forest-green);
				display: inline-block;
			}
		}
	}
	.my-progressbar-status {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1 1;
		.mps-left {
			margin-right: 15px;
			p {
				font-size: 14px;
				line-height: 19px;
				color: var(--oslo-gray);
				margin: 0;
				font-weight: 700;
				display: flex;

				.status-name {
					color: #000;
					margin-left: 4px;

					+ span {
						display: inline-flex;
					}
				}
				.status-icon {
					color: var(--theme-primary);
					font-size: 16px;
					margin-left: 6px;
					cursor: pointer;
				}
				.right-icon {
					font-size: 12px;
					margin-left: 2px;
					transform: translateY(-3px);
				}
			}
		}
		.mps-right {
			text-align: right;
		}
	}
}
.my-progressbar-wrap {
	width: 100%;
	margin: 10px auto 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	.my-progressbar-inner {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;

		li {
			width: calc(100% / 4);
			position: relative;

			.progress-line {
				width: 100%;
				position: absolute;
				border-top: 2px solid;
				top: calc(50% - 1px);
				left: 50%;

				&.gray {
					border-top: 2px solid transparent;
					background-image: linear-gradient(
						to right,
						rgb(158, 158, 158) 33%,
						rgba(158, 158, 158, 0) 0%
					);
					background-position: bottom;
					background-size: 14px 2px;
					background-repeat: repeat-x;
				}
			}
			.progress-circle {
				width: 22px;
				height: 22px;
				border-radius: 50%;
				background-color: #fff;
				border: 2px solid #9e9e9f;
				position: relative;
				outline: 3px solid #fff;
				transform: translateX(-50%);
				left: 50%;

				.progress-icon {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 10px;
					color: #fff;
				}
			}
		}

		&.job-prgressbar {
			li {
				.progress-line {
					border-color: #b1b1b1;
				}
				.progress-circle {
					border-color: #b1b1b1;
					background-color: #b1b1b1;
				}

				&.active {
					.progress-line {
						border-color: #14d6d3;
					}
					.progress-circle {
						border-color: #14d6d3;
						background-color: #14d6d3;
					}
				}
			}
		}
		&.progressbar-single {
			margin-bottom: 30px;
			li {
				width: auto;
				flex: 1 1 auto;

				&:last-child {
					.progress-line {
						display: none;
					}
				}
			}

			.progress-text {
				position: absolute;
				width: 100%;
				left: 0;
				font-size: 13px;
				color: #000;
				line-height: 18px;
				margin-top: 6px;
				text-align: center;
			}
		}
	}
	.my-progressbar-text {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		margin-top: 5px;
		justify-content: space-around;

		li {
			position: relative;
			font-size: 13px;
			line-height: 16px;
			color: #292d32;
			text-align: center;
			flex: 1;
		}
	}
}
.referrrals-profile {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
	padding: 24px 22px;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 0 30px;

	.referrrals-profile-left {
		max-width: 68%;
		width: 100%;

		.rpf-content {
			display: flex;
		}
		.rpf-content-right {
			width: 100%;

			.rpfc-right-text {
				margin: 0 0 6px;
			}

			.content-right-name {
				font-size: 22px;
				line-height: 26px;
				font-weight: 700;
				margin: 0 0 20px;
			}
			.cf-frp-name {
				&.custom-form-group {
					display: flex;
					align-items: center;
					.custom-input {
						+ .custom-input {
							margin-left: 20px;
						}
					}
				}
			}
			.content-right-link {
				display: inline-flex;
				align-items: center;
				font-size: 16px;
				line-height: 20px;
				word-break: break-word;
				font-weight: 600;

				i,
				.anticon {
					color: #8e99a3;
					margin-right: 10px;
					width: 24px;
				}
			}
			.content-right-heading {
				font-size: 18px;
				line-height: 18px;
				font-weight: 700;
				margin: 18px 0 8px;
			}
		}
		.rpfc-right-icons {
			display: flex;
			align-items: center;
			margin: 0 0 14px;

			a {
				font-size: 18px;
				line-height: 18px;

				img {
					width: 18px;
					height: 18px;
				}
				+ a {
					margin-left: 8px;
				}
			}
		}
	}
	.referrrals-profile-right {
		max-width: 28%;
		width: 100%;
		text-align: right;
		align-items: flex-end;
		display: flex;
		flex-direction: column;
	}
	.rpf-referral-info {
		p {
			font-size: 14px;
			line-height: 18px;
			margin: 0 0 8px;
			font-weight: 400;
		}
		h5 {
			color: #999;
			font-weight: 700;
			font-size: 18px;
			line-height: 18px;
		}
	}
}
.profile-right-btn-grp {
	display: flex;
	justify-content: flex-end;

	.ant-btn {
		+ .ant-btn {
			margin-left: 10px;
		}
	}
}
.referrrals-details-heading {
	font-size: 18px;
	line-height: 18px;
	font-weight: 700;
	margin: 0 0 14px;
}
.rd-wrap {
	.ant-row {
		display: flex;
		flex-wrap: wrap;

		.ant-col {
			height: 100%;
			padding-bottom: 16px;

			> .ant-list-item {
				height: 100%;
				margin: 0;
			}
		}
	}
	.ant-list-pagination {
		text-align: center;
	}
}
.rd-card {
	background-color: #fff;
	border: 1px solid #dddddd;
	padding: 16px;
	border-radius: 10px;
	height: 225px;

	.ant-card-body {
		padding: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.rd-card-top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		flex: 1;

		.rdc-left {
			margin-right: 0;
			max-width: 48%;
			word-break: break-word;
			display: flex;
			flex-direction: column;

			.rdc-name {
				font-size: 16px;
				line-height: 22px;
				color: #262626;
				margin-bottom: 6px;
				font-weight: 700;
				display: inline-block;
				margin-left: 0;

				&.rdc-acceptance {
					color: var(--oslo-gray);
				}
			}
			ul {
				padding: 0;
				list-style: none;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 0;

				li {
					display: flex;
					align-items: center;
					font-size: 14px;
					color: var(--oslo-gray);
					line-height: 14px;
					margin: 0;
					max-width: 100%;
					margin-bottom: 6px;

					i {
						margin-right: 4px;
						font-size: 18px;
					}
					span {
						width: 100%;
					}
					&:not(:last-child) {
						margin-right: 10px;
					}
				}
			}
			.rd-card-bonus {
				font-weight: 700;
				margin-bottom: 0;
			}
			.rdc-action-btn {
				display: flex;
				align-items: center;
				margin: 0 0 10px;
			}
		}
		.rdc-right {
			display: flex;
			flex-direction: column;
			text-align: right;
			max-width: 48%;

			.rdc-referred {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 6px;
				font-weight: 400;
				display: inline-block;
				color: var(--oslo-gray);

				.rdc-referred-self {
					color: var(--tan-hide);
				}
				a {
					font-weight: 700;
					margin-left: 8px;
				}
				.text-gray {
					margin-left: 8px;
				}
			}
			.rdc-referred-date {
				> span {
					font-size: 14px;
					line-height: 19px;
					margin-bottom: 6px;
					font-weight: 400;
					display: inline-flex;
					align-items: center;
					color: var(--oslo-gray);
				}

				i {
					color: var(--oslo-gray);
					margin-right: 6px;
				}
			}
			.rdc-referred-status {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 6px;
				font-weight: 400;
				display: inline-block;
				color: #444;

				span {
					color: var(--oslo-gray);
					margin-right: 6px;
				}
			}
			.rd-card-bonus {
				font-size: 14px;
				line-height: 14px;
				font-weight: 400;
				margin: 0 0 10px;
				color: var(--forest-green);
			}
		}
		.rdc-social-icons {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 0 6px;
			font-size: 14px;
			line-height: 14px;
			color: var(--oslo-gray);

			> span {
				display: flex;
				align-items: center;

				i:not(:first-child) {
					margin-left: 12px;
				}
			}

			> a {
				color: var(--theme-primary);
				font-size: 14px;
				line-height: 14px;

				+ a {
					margin-left: 12px;
				}
			}
			> i {
				color: var(--oslo-gray);
				font-size: 16px;
				&:not(:first-child) {
					margin-left: 12px;
				}
			}
		}
	}
	.rdc-status-dropdown {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;

		.status-dropdown-label {
			font-size: 14px;
			line-height: 14px;
			font-weight: 700;
			color: var(--oslo-gray);
			margin: 0;
			.status-label-color {
				color: var(--theme-primary);
				margin-left: 6px;
				&.Hired {
					color: var(--forest-green);
					&.Not {
						color: var(--flamingo);
					}
				}
				&.Applied {
					color: var(--tan-hide);
				}
				&.Ineligible,
				&.Declined {
					color: var(--flamingo);
				}
				&.Transferred,
				&.Inactive,
				&.Response {
					color: var(--oslo-gray);
				}
			}
		}
		.status-dropdown-btn {
			padding: 0;
			border: 0;
			height: auto;
			margin-left: 10px;
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 14px;

			i {
				font-size: 6px;
				margin-left: 4px;
			}
			span {
				svg {
					vertical-align: middle;
				}
			}
			span + .anticon {
				margin-left: 4px;
			}
		}
		.ant-btn-primary {
			margin-left: auto;
		}
	}
}
.rd-actions {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 0 40px;

	.rda-name {
		font-size: 20px;
		line-height: 20px;
		flex: 1;
		font-weight: 700;
		margin-right: 15px;
	}
	.rda-filter {
		display: inline-flex;
		align-items: center;
		.rda-label {
			font-size: 16px;
			line-height: 16px;
			font-weight: 600;
			margin-right: 10px;
		}
		ul {
			display: flex;
			align-items: center;
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				+ li {
					margin-left: 10px;
				}
			}
		}
	}
}
.mj-referral {
	.ant-row {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;

		.manage-job-details {
			height: 100%;
		}
		.mj-referral-card {
			&:not(:last-child) {
				margin: 0 0 12px;
			}
		}
	}
}
.mj-referral-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;

	&.mjc-gray {
		background: #f1f1f1;
	}

	.mjr-card-head {
		padding: 24px 22px 20px;
		border-radius: 15px 15px 0 0;
		background-color: var(--theme-primary);
		color: #fff;
	}
	.mjr-card-body {
		padding: 24px 22px;

		p {
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;
			margin: 0 0 14px;

			a {
				color: var(--theme-primary);
			}
			.btn-link {
				margin-left: 20px;
			}

			span {
				font-weight: 400;
				margin-left: 6px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
		h5 {
			font-size: 16px;
			line-height: 20px;
			font-weight: 700;
			margin: 0 0 20px;

			span {
				font-weight: 400;
				margin-left: 10px;
			}
		}
	}
	.mjr-card-body.centered {
		display: flex;
		align-items: center;
		justify-content: center;

		h4 {
			font-size: 30px;
			font-weight: 700;
			line-height: 30px;
			color: #000;
			margin: 0 0 6px;
			padding: 0.5rem 1.25rem;
			border-bottom: 4px solid black;
		}

		.mjr-card-button {
			height: fit-content;
			width: fit-content;
			border: none;
			padding: 0;
		}

		.mjr-card-button:hover {
			h4 {
				transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: var(--theme-primary);
				border-bottom-color: var(--theme-primary);
			}
		}
	}
	.mjr-summary-title {
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		text-align: center;
		margin: 0 0 4px;
		color: #fff;
	}
	.mjr-summary-count {
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			min-width: 75px;
			padding: 6px;
			color: #fff;
			text-align: center;
			position: relative;

			h4 {
				font-size: 30px;
				line-height: 30px;
				font-weight: 700;
				margin: 0 0 6px;
				color: #fff;
			}
			span {
				font-size: 14px;
				line-height: 14px;
				font-weight: 400;
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}
	.mjr-view-share {
		display: flex;
		align-items: center;
		justify-content: space-between;

		label {
			font-size: 14px;
			line-height: 14px;
			font-weight: 700;
			margin: 0;
			color: #000;
		}
		&:not(:last-child) {
			margin: 0 0 26px;
		}
	}
}
.rpf-qa {
	margin: 0 0 10px;

	p,
	.qa-row {
		font-size: 14px;
		line-height: 18px;
		margin: 0 0 4px !important;
		font-weight: 400;

		label {
			font-weight: 700;
			margin: 0;
			color: #000;
			width: 52px;

			&.qa-label {
				width: 100%;
			}
		}
	}
}

.content-right-comment {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	margin: 0 0 6px;
	flex-wrap: wrap;

	span {
		font-weight: 700;
	}

	i {
		color: #8e99a3;
		margin-right: 10px;
		width: 24px;
		font-size: 18px;
	}
	&.text-italic {
		font-style: italic;
	}
}
.select-job-filter {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 0 20px;

	.search-input {
		margin-right: 0;
	}
}
.select-job-card-wrap {
	.ant-row {
		display: flex;
		flex-wrap: wrap;
		.ant-col {
			height: 100%;
			padding-bottom: 16px;
		}
		.ant-list-item {
			margin: 0;
			height: 100%;
		}
	}
}
.select-job-card {
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
	padding: 20px;
	height: 100%;

	.select-job-title {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin: 0 0 14px;
	}
	.select-job-info {
		list-style: none;
		padding: 0;
		margin: 0 0 8px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			display: flex;
			font-size: 14px;
			line-height: 14px;
			color: var(--oslo-gray);
			align-items: center;
			margin: 0 0 6px;
			i {
				color: #afb1b3;
				margin-right: 4px;
				font-size: 18px;
			}

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
	.select-job-bonus {
		font-size: 14px;
		line-height: 14px;
		font-weight: 700;
		margin: 0;
		span {
			margin-left: 6px;
		}
	}
}
.referral-filter {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 20px;
}
.referral-lead-table-social {
	white-space: nowrap;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	> * {
		margin: 0 4px;
	}
}

@media (max-width: 1199px) {
	.mj-referral {
		.ant-row {
			.manage-job-details {
				height: auto;
				margin: 0 0 12px;
			}
		}
	}
	.referrrals-profile {
		.referrrals-profile-left {
			max-width: 100%;
		}
		.referrrals-profile-right {
			max-width: 100%;
		}
	}
}

@media (max-width: 767px) {
	.my-referrals-card {
		.my-referrals-top {
			flex-direction: column;
			.left {
				margin-right: 0;
			}
			.right {
				align-items: flex-start;
				text-align: left;
			}
		}
	}
	.manage-job-details {
		padding: 18px 16px;
	}
	.mj-referral-card {
		.mjr-card-head {
			padding: 18px 16px 14px;
		}
		.mjr-card-body {
			padding: 18px 16px;
		}
		.mjr-summary-count {
			li {
				h4 {
					font-size: 26px;
					line-height: 26px;
				}
			}
		}
	}
	.rd-card {
		.rd-card-top {
			flex-wrap: nowrap;

			.rdc-left {
				margin-right: 0;
				width: 100%;
				max-width: 100%;
			}
			.rdc-right {
				text-align: right;
				max-width: 100%;
				width: 100%;
			}
		}
	}
	.referrrals-profile {
		padding: 18px 16px;
		margin: 0 0 14px;

		.referrrals-profile-left {
			.rpf-content {
				flex-wrap: wrap;
			}
			.rpf-content-right {
				.cf-frp-name {
					&.custom-form-group {
						display: block;

						.custom-input {
							+ .custom-input {
								margin-left: 0;
								margin-top: 20px;
							}
						}
					}
				}
			}
		}
	}
	.profile-right-btn-grp {
		.ant-btn {
			flex: 1;
		}
	}
	.select-job-filter {
		margin-bottom: 10px;

		.switch-container {
			margin-bottom: 10px;
		}
	}
}

.vl-back-to-top {
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.5s ease-in-out,
		visibility 0.5s ease-in-out;
	position: absolute;
	bottom: 16px;
	left: 50%;
	transform: translateX(calc(-50% - 8px));
	padding: 12px 16px !important;
	background-color: var(--dove-gray);
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.vl-back-to-top.show {
	opacity: 0.9;
	visibility: visible;
}
.vl-back-to-top:hover,
.vl-back-to-top:focus {
	background-color: var(--oslo-gray);
	color: #fff;
	border: none;
}

.referral-card {
	width: 100%;
	height: 250px;
	border-radius: 10px;
	border: 1px solid #ddd;
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	overflow: hidden;
}

.referral-footer-card {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.referral-card-content {
	flex: 1;
	overflow: hidden;
}

.referral-contact {
	display: flex;
	flex-direction: column;
	max-width: 150px;
}

.referral-contact-name {
	flex-shrink: 0;
}

.referral-contact-paragraph {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.2;
	max-height: 2.4em;
	margin-bottom: 4px !important;
}

.referral-date-wrap {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.referral-date {
	font-size: 14px;
	color: grey;
	margin-left: 8px;
	margin-right: 5px;
}

.referral-job {
	display: flex;
	flex-direction: column;
	row-gap: 5px;
	align-items: flex-end;
	max-width: 200px;
}

.referral-job-title {
	flex-shrink: 0;
	margin-bottom: 0;
}

.referral-job-paragraph {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.2;
	max-height: 2.4em;
	text-align: right;
	margin-bottom: 0;
}

.referral-job-title > div.ant-typography {
	margin-bottom: 0 !important;
}

.bonus-info {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	margin-bottom: 0;
	cursor: pointer;
}

.admin-note-icon {
	margin-right: 5px;
}

.total-bonus {
	color: green;
	font-size: 16px;
	font-weight: bold;
	margin-left: 8px;
}

.resend-icon {
	color: grey;
	margin-left: 8px;
	margin-right: 4px;
	cursor: pointer;
	font-size: 18px;
}

.referral-info-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.my-referrals-referral-card > div.ant-card-body {
	height: 250px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.view-bonus-details {
	color: #0686d0 !important;
	cursor: pointer;
}
