.employee-detail-wrap {
  display: flex;
  justify-content: space-between;

  .ed-left {
    flex: 0 0 auto;
    width: calc(60% - 15px);
    background-color: #fff;
    padding: 24px 22px;
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-bottom: 20px;

    .ed-actions {
      display: flex;
      justify-content: flex-end;
      margin: 0 0 8px;

      .ant-btn:not(:last-child) {
        margin-right: 8px;
      } 
    }

    .ed-card {
      display: flex;

      .employee-image {
        margin-right: 14px;
        flex: 0 0 auto;
        width: 57px;
        height: 57px;
        border-radius: 10px;
        background-color: #8e99a3;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-clip: padding-box;  
        -moz-background-clip: padding;  
        -webkit-background-clip: padding;
        .custom-img {
          background-color:#fff;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 9px;
        }
      }
      .employee-detail {
        margin-right: 14px;

        .ed-name {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          margin: 0 0 20px;
        }
        .custom-form-group {
          display: flex;
          align-items: center;
          label {
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            margin-right: 4px;
            flex: 0 0 auto;
            margin-bottom: 0;
            min-width: 110px;
          }
          &.block-details {
            flex-wrap: wrap;
            label {
              margin: 0 0 4px;
            }
          }
          .custom-input {
            & ~ button {
              margin-left: 6px;
            }
          }
          .select-currency {
            width: 100%;
          }
        }
        .ed-edit-name {
          display: flex;
          justify-content: space-between;

          .custom-form-group {
            width: calc(50% - 5px);
          }
        }
        .ed-email {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 14px;

          i {
            color: #8e99a3;
            margin-right: 10px;
          }
        }
        .ed-other-details {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          margin: 0 0 18px;
          .label {
            font-weight: 700;
            margin-right: 4px;
            min-width: 120px;
            display: inline-block;
          }
        }
        .ed-heading {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          margin: 0 0 16px;
        }
        .btn-link {
          padding: 0;
        }
      }
      
      .ed-confirmation-btn {
        display: flex;
        flex-direction: column;
        button {
          & + button {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .ed-right {
    flex: 0 0 auto;
    width: 40%;

    .ed-card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
      margin-bottom: 14px;
      overflow: hidden;

      .ed-card-header {
        background-color: var(--theme-primary);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 22px 14px;
        color: #fff;

        &.bg-1D8627 {
          background-color: var(--forest-green);
        }

        &.bg-14D6D3 {
          background-color: var(--bright-turquoise);
        }

        .card-header-text {
          font-size: 16px;
          margin: 0 0 10px;
          line-height: 20px;
        }
        h3 {
          font-size: 30px;
          line-height: 34px;
          font-weight: 700;
          margin: 0 0 10px;
          color: #fff;
        }
      }
      .ed-card-body {
        padding: 24px 22px;
        .card-body-detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 20px;

          label {
            font-size: 16px;
            line-height: 20px;
            color: #000;
            font-weight: 700;
            margin-right: 10px;
            margin-bottom: 0;
          }
          p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            margin: 0;
          }
          .custom-form-group {
            margin: 0;
            max-width: 300px;
            width: 100%;
          }
          &:last-child {
            margin: 0;
          }
        }
        .card-body-action {
          display: flex;
          justify-content: flex-end;

          .add-btn {
            & + .add-btn {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
.ed-title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .employee-detail-wrap {
    flex-wrap: wrap;

    .ed-left {
      width: 100%;
    }
    .ed-right {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .employee-detail-wrap {
    .ed-left {
      .ed-card {
        flex-wrap: wrap;
        padding: 18px 14px;

        .employee-detail {
          margin-right: 0;

          .ed-email {
            word-break: break-word;
          }
          .ed-edit-name {
            flex-wrap: wrap;

            .custom-form-group {
              width: 100%;
            }
          }
          .custom-form-group {
            flex-wrap: wrap;

            label {
              margin-bottom: 4px;
            }
            .custom-input {
              ~ button {
                margin-left: 0;
                margin-top: 10px;
              }
            }
          }
          .ed-other-details {
            .label {
              min-width: 100%;
              display: block;
              margin-bottom: 4px;
            }
          }
        }
        .ed-actions {
          order: -1;
          width: 100%;
          margin-bottom: 20px;
        }

        .employee-image {
          margin-bottom: 14px;
        }
      }
    }
    .ed-right {
      .ed-card {
        .ed-card-header {
          padding: 18px 14px 8px;
        }
        .ed-card-body {
          padding: 18px 14px;

          .card-body-detail {
            flex-wrap: wrap;

            label {
              margin-right: 0;
              margin-bottom: 4px;
              width: 100%;
            }
            .custom-form-group {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
