.reward-modal {
	.badges-list {
		display: flex;
		flex-wrap: wrap;
		row-gap: 20px;
		.badge-container {
			// flex-grow: 1;
			width: 33%;
		}
		.nominate-button {
			width: 60% !important;
			max-width: max-content;
		}
	}
}
