.tilt-container {
    transform-style: preserve-3d;
    padding: 0;
}

.tilt-container:hover .badge-shadow {
    filter: drop-shadow(0rem 0.6rem 0.8rem rgba(0, 0, 0, 0.7));
}

.js-tilt-glare {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    transform-style: preserve-3d;
}

.js-tilt-glare-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    background-image: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%);
    transform: rotate(180deg) translate(-50%, -50%);
    transform-origin: 0% 0%;
    opacity: 0;
    width: 150%;
    height: 150%;
}

.badge-content {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.badge-inner-circle {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    width: 74px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge-icon {
    width: 64px;
    height: 64px;
    object-fit: contain;
}

.badge-inner {
    transition: transform 0.3s ease;
}

.badge-circle {
    aspect-ratio: 1;
    border-radius: 50%;
    width: 130px;
    div {
        div {
            .badge-inner-circle {
                width: 80px;
                height: 80px;
            }
        }
    }
}

.badge-square {
    border-radius: 0.5rem;
}

.badge-hexagon {
    aspect-ratio: 1;
    clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
}

.badge-flower {
    aspect-ratio: 1;
    --g:/20.56% 20.56% radial-gradient(closest-side at center, #000 calc(71% - 1px), #0000 71%) no-repeat;
    mask: 100% 50% var(--g), 93.301% 75% var(--g), 75% 93.301% var(--g), 50% 100% var(--g), 25% 93.301% var(--g), 6.699% 75% var(--g), 0% 50% var(--g), 6.699% 25% var(--g), 25% 6.699% var(--g), 50% 0% var(--g), 75% 6.699% var(--g), 93.301% 25% var(--g),
    radial-gradient(closest-side at center, #000 38.366%, #0000 calc(38.366% + 1px));
}


.badge-wavy {
    --s: 130px;

    width: var(--s);
    aspect-ratio: 1;
    --g:/calc(var(--s)*0.201) calc(var(--s)*0.201) radial-gradient(closest-side at 50% 50%,#000 99%,#0000 101%) no-repeat;
    mask: calc(50% + var(--s)*0.369) calc(50% + var(--s)*0) var(--g),calc(50% + var(--s)*0.261) calc(50% + var(--s)*0.261) var(--g),calc(50% + var(--s)*0) calc(50% + var(--s)*0.369) var(--g),calc(50% + var(--s)*-0.261) calc(50% + var(--s)*0.261) var(--g),calc(50% + var(--s)*-0.369) calc(50% + var(--s)*0) var(--g),calc(50% + var(--s)*-0.261) calc(50% + var(--s)*-0.261) var(--g),calc(50% + var(--s)*0) calc(50% + var(--s)*-0.369) var(--g),calc(50% + var(--s)*0.261) calc(50% + var(--s)*-0.261) var(--g),radial-gradient(calc(var(--s)*0.418),#000 99%,#0000 101%) intersect,radial-gradient(#000 0 0) exclude,calc(50% + var(--s)*0.447) calc(50% + var(--s)*0.185) var(--g),calc(50% + var(--s)*0.185) calc(50% + var(--s)*0.447) var(--g),calc(50% + var(--s)*-0.185) calc(50% + var(--s)*0.447) var(--g),calc(50% + var(--s)*-0.447) calc(50% + var(--s)*0.185) var(--g),calc(50% + var(--s)*-0.447) calc(50% + var(--s)*-0.185) var(--g),calc(50% + var(--s)*-0.185) calc(50% + var(--s)*-0.447) var(--g),calc(50% + var(--s)*0.185) calc(50% + var(--s)*-0.447) var(--g),calc(50% + var(--s)*0.447) calc(50% + var(--s)*-0.185) var(--g);
}


.badge-starburst {
    width: 130px;
    aspect-ratio: 1;
    clip-path: polygon(99.52% 56.89%,76.98% 63.11%,86.02% 84.68%,64.12% 76.47%,58.75% 99.23%,45.87% 79.71%,28.14% 94.97%,29.19% 71.61%,5.89% 73.54%,20.46% 55.25%,0.48% 43.11%,23.02% 36.89%,13.98% 15.32%,35.88% 23.53%,41.25% 0.77%,54.13% 20.29%,71.86% 5.03%,70.81% 28.39%,94.11% 26.46%,79.54% 44.75%);
    div {
        div {
            .badge-inner-circle {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.badge-shield {
    aspect-ratio: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%);
}
