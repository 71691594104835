.gift-card-title-wrap {
    display: flex;

    .gift-card-title {
        margin-right: 12px;

        h5 {
            text-transform: uppercase;
            margin: 0 0 2px;
            font-size: 16px;
            font-weight: 600;
        }
        p {
            margin: 0;
        }
    }
    .custom-form-group {
        margin: 0;
        min-width: 280px;

        .custom-input {
            .ant-select-selector {
                border-radius: 20px;

                &__rendered {
                    margin-left: 4px;
                }
            }
        }

    }

    
}
.cart-items {
    width: 20px;
    height: 20px;
    background-color: var(--tan-hide);
    border-radius: 50%;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000;
    position: absolute;
    top: -8px;
    left: -8px;
}

.product-card {
    border: 1px solid #DDDDDD;
    border-radius: 16px;
    overflow: hidden;
    margin: 0 0 30px;

    .product-card-img {
        position: relative;
        padding-bottom: 62%;
        overflow: hidden;

        img {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
            min-height: 100%;
            object-fit: cover;
        }
    }
    .product-card-info {
        padding: 12px;
        background: #fff;

        .product-title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }
        .product-price {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
        }
    }
}
.denominations-wrap {
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 8px;
    }
}
.cart-list-items {
    display: flex;
    align-items: center;
    padding: 8px 8px;
    border-bottom: 1px solid #e5e5e5;

    .cart-list-image {
        width: 60px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 8px;

        img {
            width: 100%;
            height: auto;
        }
    }
    .info-price {
        flex: 1;
        margin-right: 8px;

        h5 {
            font-size: 14px;
            line-height: 16px;
            margin: 0 0 4px;
            font-weight: 600;
        }
        p {
            font-size: 12px;
            line-height: 16px;
            margin: 0;
            font-weight: 600;
        }
    }
}