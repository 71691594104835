.menu-container {
	position: sticky;
	top: 0px;
	z-index: 9;
}

.nav-menu {
	display: flex;
	align-items: center;
	padding: 0px 15px;
	width: 100%;
	justify-content: space-between;
	background-color: #ffffff;
	position: relative;
	min-height: 64px;

	.top-menu {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;

		.menu-item {
			font-size: 14px;
			font-weight: 600;
			line-height: 60px;
			text-transform: uppercase;
			position: relative;

			&::after {
				content: '';
				width: 0;
				left: 0;
				bottom: 0;
				height: 4px;
				display: block;
				background-color: var(--lochmara);
				transition: all 0.3s ease-in;
			}

			&:hover,
			&.active {
				&::after {
					width: 100%;
				}
			}

			a {
				padding: 10px 24px;
				color: #000000;
			}
		}
	}

	.header-right {
		display: flex;
		align-items: center;
		margin-left: auto;

		.ant-badge {
			cursor: pointer;
			[class*='icon-'] {
				font-size: 20px;
				color: var(--oslo-gray);
			}

			.ant-badge-count {
				.ant-scroll-number-only > p.ant-scroll-number-only-unit {
					color: #ffffff;
				}
			}
		}

		.company-name {
			font-size: 12px;
			line-height: 20px;
			font-weight: 800;
			text-transform: uppercase;
			margin: 0 18px;
		}

		.user-name {
			cursor: pointer;
			background: #cfcfcf;
			width: 36px;
			height: 36px;
			color: white;
			font-size: 20px;
			font-weight: 600;
			line-height: 36px;
			margin-bottom: 0;
		}

		.user-image {
			cursor: pointer;
		}
	}

	.grid-icon {
		display: none;
		flex: 0 0 auto;
		width: 24px;
		height: 24px;
		position: relative;
		cursor: pointer;
		margin-left: auto;
		margin-right: 18px;

		span {
			height: 4px;
			position: absolute;
			width: 4px;
			background-color: #384a6e;
			border-radius: 50%;
			left: calc(50% - 2px);

			&::before,
			&::after {
				content: '';
				height: 4px;
				width: 4px;
				background-color: #384a6e;
				border-radius: 50%;
				position: absolute;
			}

			&::before {
				left: -6px;
			}

			&::after {
				left: 6px;
			}

			&:first-child {
				top: 4px;
			}

			&:nth-child(2) {
				top: calc(50% - 2px);
			}

			&:last-child {
				top: calc(100% - 8px);
			}
		}
	}

	.hamburger {
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: 24px;
		height: 24px;
		cursor: pointer;
		display: none;
		margin-right: 15px;

		.bar {
			height: 3px;
			background-color: #384a6e;
			margin: 4px 0;
		}
	}
}

@media (max-width: 1199px) {
	.nav-menu {
		.top-menu {
			.menu-item {
				a {
					padding: 10px 8px;
				}
			}
		}
		.header-right {
			.company-name {
				margin: 0 10px;
			}
		}
	}
}

@media (max-width: 991px) {
	.nav-menu {
		flex-wrap: wrap;
		.hamburger {
			display: inline-block;
		}
		.top-menu {
			order: 4;
			width: calc(100% + 30px);
			border-top: 1px solid #dddddd;
			margin-left: -15px;
			margin-right: -15px;
			padding: 0 15px;

			.menu-item {
				line-height: 51px;
				padding-top: 4px;
			}
		}

		.header-right {
			display: flex;
			align-items: center;
			padding: 12px 0;
		}
	}
}

@media (max-width: 767px) {
	.nav-menu {
		.top-menu {
			overflow-x: auto;

			.menu-item {
				a {
					padding: 10px 24px;
					white-space: nowrap;
				}
			}
		}
	}
}
