.rewards-progress-reward-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  width: 100%;
}

.rewards-progress-reward-list li {
  text-align: center;
  font-size: 12px;
  color: white;
  width: 33.33%;
  display: flex;
  flex-direction: column;
}

.rewards-progress-reward-level {
  font-size: 16px;
  color: #6e6e6e;
  margin-bottom: 0;
}

.rewards-progress-reward-description {
  margin-bottom: 0;
}

.icon-wrapper {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

    svg {
      font-size: 32px;

    }
}

.ant-steps-icon {
  left: -25% !important;
}

.ant-steps-item-tail::after {
  background-color: #e0e0e0 !important;
  height: 10px;
  width: 100%;
  position: absolute;
  top: 15px;
}

.tail-pink .ant-steps-item-tail::after {
  background-color: #f650a9 !important;
}
