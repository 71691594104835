.d-two-col-data {
  display: flex;
  justify-content: space-between;

  .d-col-left {
    flex: 1;
    max-width: calc(34% - 10px);
    display: flex;
    margin-bottom: 16px;
  }
  .d-col-right {
    flex: 1;
    max-width: calc(66% - 10px);
    display: flex;
    margin-bottom: 16px;
  }
}
.nd-custom-card {
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 12px;

  .nd-custom-card-head {
    padding: 14px 16px;
    border-bottom: 1px solid #e9e9e9;

    .nd-custom-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin: 0;
    }
  }
  .nd-custom-card-body {
    padding: 16px;
  }
}
.d-five-col-data {
  display: flex;
  justify-content: space-between;

  .d-col {
    flex: 1;
    max-width: calc(20% - 16px);
    display: flex;
    margin-bottom: 20px;

    .nd-card {
      background-color: #fff;
      border-radius: 12px;
      position: relative;
      overflow: hidden;
      width: 100%;

      .nd-data {
        top: 50%;
        left: 0;
        width: 100%;
        z-index: 0;
        padding: 16px;
        position: absolute;
        transform: translateY(-50%);

        .nd-title {
          font-size: 14px;
          line-height: 18px;
          color: #000;
          text-align: center;
          margin: 0 0 20px;
        }
        .nd-col-data {
          font-size: 40px;
          line-height: 42px;
          font-weight: 700;
          color: #484848;
          text-align: center;
          margin: 0 0 10px;
        }
        .nd-col-percent {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;

          i {
            margin-right: 8px;
          }

          &.positive {
            color: #329c3d;
          }
          &.negative {
            color: #f43943;
          }
        }
      }
      .nd-chart {
        position: relative;
        z-index: 0;
        bottom: -1px;

        rect {
          fill: none;
        }
      }
    }
  }
}
