.select-winner-presentation {
	.d-flex {
		display: flex;
	}
	.bread-crubs {
		padding: 25px;
		padding-left: 0px;
		.bread-crubs-text {
			font-size: 16px;
		}
	}
	// .page-heading-left{
	//     font-size:  18px !important;
	// }
	.action-select {
		color: #1d8627;
		border-color: #1d8627;
	}
	.action-undo {
		background-color: #1d8627;
		border-color: #1d8627;
	}
}

.nomination-rewards-table-container {
	.nomination-rewards-table {
		background-color: white !important;
		padding: 12px !important;
		border-radius: 12px !important;
	}
}

.choose-reward-input {
	&.reward-activity-input {
		.ant-select-selection {
			border-radius: 50px;
		}
	}
}

// .ant-btn-link {
//     color: #0686d0 !important;
// }
