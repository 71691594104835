.landing-background {
	height: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
}

.job-description-container {
	p {
		color: #404447;
		font-weight: 400;
		font-size: 16px;
	}
}
