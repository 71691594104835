.fade-bottom {
	opacity: 1;
	mask-image: linear-gradient(
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 0.9),
		rgba(0, 0, 0, 0.7),
		rgba(0, 0, 0, 0)
	);
}
.anticon svg {
	vertical-align: baseline;
}
.slick-arrow {
	 svg {
		height: 20px;
		width: 20px;
		 fill: #aeadaf;
		 &:hover {
			 fill: #6f6e70;
		 }
	}
}
.slick-dots li button:before {
	font-size: 12px;
	color: #aeadaf;
}
.slick-dots li.slick-active button:before {
	font-size: 12px;
	color: #6f6e70;
}
.gradient-text {
	font-size: 14px;
	font-weight: 800;
	font-family: sans-serif;
	background: linear-gradient(to left, #3076bb, #ee3b40);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent; /* For Safari */
	color: transparent; /* Fallback: if the above doesn't work */
}
.gradient-text-container {
	background-color: #fff;
	border-radius: 4px;
	margin-bottom: 0.5rem;
	margin-left: 6px;
	padding: 0 4px;
	box-shadow:
		0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
		0 1px calc(2px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
}
.preview-tile-edit {
	position: absolute;
	right: 20px;
	top: 52px;
	z-index: 2;
}
.preview-tile-wrapper {
	width: 100%;
	height: 100%;
	border-radius: 16px;
	text-align: center;
}
.preview-tile-header {
	font-size: 4vw;
	font-weight: 800;
	margin: 0;
	margin-bottom: 1.2vw;
	height: 33%;
}
.preview-tile-subheader {
	font-size: 1.5vw;
	font-weight: 600;
	margin: 0.5vw 0 0.8vw;
}
.preview-tile-button {
	display: flex;
	justify-content: center;
	align-items: center;
	div {
		background-color: #fff;
		border: none;
		border-radius: 50px;
		color: #3076bb;
		font-size: 0.9vw;
		font-weight: 700;
		padding: 0.5vw 2vw;
	}
}
