.home {
	.rewards-progress {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 12px !important;
	}
	.rewards-progress-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 2px !important;
	}
	.rewards-progress-description {
		font-size: 11px;
		color: var(--oslo-gray);
	}
	.rewards-progress-view-rewards {
		cursor: pointer;
		font-size: 12px;
		color: #2596be;
	}


}


