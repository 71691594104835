.export-selector {
	border-radius: 3px;
	justify-content: space-between;
	width: 105px;
	border-color: #d9d9d9;
	color: rgba(0, 0, 0, 0.65);
}
.export-selector:hover {
	color: rgba(0, 0, 0, 0.65);
	border-color: #40a9ff;
}

.export-selector:focus {
	color: rgba(0, 0, 0, 0.65);
	border-color: #40a9ff;
	.export-arrow {
		transform: rotate(180deg);
	}
}

.export-arrow {
	transition: transform 0.2s ease;
}
