.nomination-rewards-table {
	.status-pending {
		color: #f7a34d;
		font-weight: bold;
	}

	.status-open {
		color: #2f8f38;
		font-weight: bold;
	}

	.status-complete {
		color: #7c7c7c;
		font-weight: bold;
	}
}
