h1,
h2,
h3 {
  color: var(--black);
}
h4, h5, h6 {
    color: var(--dove-gray);
}
p {
    color: var(--oslo-gray);
}
a {
    color: var(--lochmara);
}