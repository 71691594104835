.physical-reward-modal {
	.line-clamp-three {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}
	.physical-reward-badge {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.physical-reward-text {
		font-size: 20px;
		font-weight: bold;
		color: black;
	}

	.physical-reward-details {
		// overflow: hidden;
		// display: -webkit-box;
		// -webkit-box-orient: vertical;
		// -webkit-line-clamp: 2;
		font-size: 14px;
		color: #a5a4a4;
	}

	.award-detalis {
		background-color: #f5f5f5;
		border-radius: 8px;
		padding: 8px;
		margin: 8px 0 16px 0;

		.award-detalis-info {
			display: flex;
			// align-items: center;
			font-size: 18px;
			font-weight: 600;

			.award-detalis-about {
				font-size: 16px;
				.award-detalis-name {
					color: var(--black);
				}
				// align-items: center;
				.award-detalis-numbers {
					color: #6f6e70;
				}
			}
		}

		.text-gray {
			color: gray;
		}
	}

	.physical-reward-details-semi-title {
		font-size: 20px;
		font-weight: 700;
	}

	.physical-social-icon {
		.social-icon {
			object-fit: contain;
			width: 50px;
			height: 50px;
			padding: 0 4px;
		}
	}
}
