@use 'mixins' as m;

.ant-btn {
	height: auto;
	padding: 8px 16px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	border-color: var(--theme-primary);
	border-radius: 42px;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.btn-min-width {
		min-width: 180px;
	}
	&:hover,
	&:focus,
	&:active {
		border-color: var(--theme-primary);
	}
	&.active {
		color: #ffffff;
		background-color: var(--theme-primary);
	}

	&.ant-btn-lg {
		line-height: 24px;
	}
	&.ant-btn-sm {
		font-size: 12px;
		font-weight: 400;
		padding: 4px 16px;
	}

	& > i + span,
	& > span + i,
	& > svg + span,
	& > span + svg {
		margin-left: 8px;
	}
	span {
		span + svg {
			margin-left: 8px;
		}
	}
}
.btn-pink {
	height: auto;
	padding: 8px 16px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	color: var(--deep-blush);
	border-color: var(--deep-blush);
	border-radius: 42px;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.btn-min-width {
		min-width: 180px;
	}
	&:hover {
		color: #f09dca;
		border-color: var(--deep-blush);
	}
	&:focus,
	&:active {
		border-color: var(--deep-blush);
	}
	&.active {
		color: #ffffff;
		background-color: var(--deep-blush);
		border-color: var(--deep-blush);
	}
	&.false {
		color: var(--deep-blush);
	}
	&.false:hover {
		color: #f09dca;
		border-color: var(--deep-blush);
	}
	&.btn-pink-lg {
		line-height: 24px;
	}
	&.btn-pink-sm {
		font-size: 12px;
		font-weight: 400;
		padding: 4px 16px;
	}

	& > i + span,
	& > span + i,
	& > svg + span,
	& > span + svg {
		margin-left: 8px;
	}
}
.ant-btn-ghost {
	color: var(--primary-btn-color, var(--theme-primary));
	border-color: var(--primary-btn-color, var(--theme-primary));

	&:hover,
	&:focus,
	&:active {
		color: var(--primary-btn-color, var(--theme-primary));
		border-color: var(--primary-btn-color, var(--theme-primary));
	}
}
.ant-btn-primary {
	color: #fff;
	// border-color: var(--menu-color, var(--cello));
	// background-color: var(--menu-color, var(--cello));
	border-color: var(--primary-btn-color, var(--theme-primary));
	background-color: var(--primary-btn-color, var(--theme-primary));
	text-shadow: none;

	&.ant-btn-background-ghost {
		color: var(--primary-btn-color, var(--theme-primary));
		border-color: var(--primary-btn-color, var(--theme-primary));

		&:hover,
		&:focus {
			color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
			border-color: var(
				--primary-btn-highlight-color,
				var(--theme-primary-highlight)
			);
		}
	}

	&:hover,
	&:focus {
		color: #fff;
		border-color: var(
			--primary-btn-highlight-color,
			var(--theme-primary-highlight)
		);
		background-color: var(
			--primary-btn-highlight-color,
			var(--theme-primary-highlight)
		);
	}
}
.ant-btn-danger {
	color: #fff;
	background-color: var(--flamingo);
	border-color: var(--flamingo);
}
.ant-btn-success {
	color: #fff;
	background-color: var(--forest-green);
	border-color: var(--forest-green);
	text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
	-webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
	box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

	&:hover,
	&:focus {
		color: #fff;
		background-color: #329c3d;
		border-color: #329c3d;
	}
}
.ant-btn-secondary {
	color: #fff;
	background-color: #6e86b3;
	border-color: #6e86b3;
	text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
	-webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
	box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

	&:hover,
	&:focus {
		color: #fff;
		background-color: #7d94c1;
		border-color: #7d94c1;
	}
}
.ant-btn-link {
	color: var(--theme-primary);
	height: auto;
	padding: 0;
	border: none;
	display: inline-flex;
	align-items: center;
	margin-right: 10px;

	[class*='icon-'] {
		font-size: 13px;

		& + span {
			margin-left: 5px;
		}
		&.btn-large {
			font-size: 20px;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&.danger {
		color: var(--flamingo);
	}
	&.white {
		color: #ffffff;
	}
}
.add-btn {
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	height: auto;
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		color: #000000;

		.icon-circle {
			transform: rotate(90deg);
		}
	}

	.icon-circle {
		color: var(--primary-btn-color, var(--flamingo));
		flex: 0 0 auto;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		line-height: 1;
		margin: 0 8px 0 0;
		transition: all 0.3s ease-in;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid var(--add-btn-color, var(--flamingo));

		i,
		.anticon {
			font-size: 14px;
			color: var(--add-btn-color, var(--sunset-orange));
		}
		.icon-spinner2 {
			@include m.animate(spin, 1s, linear, infinite);
		}
	}
	&.btn-lg {
		.icon-circle {
			width: 36px;
			height: 36px;

			i {
				font-size: 16px;
			}
		}
	}
	&.btn-sm {
		font-size: 14px;
		.icon-circle {
			width: 24px;
			height: 24px;
			i {
				font-size: 12px;
			}
		}
	}
	&.top-icon-btn {
		flex-direction: column;

		.icon-circle {
			margin-bottom: 10px;
			margin-right: 0;

			+ span {
				font-size: 14px;
			}
		}
	}
	&.outline-primary {
		.icon-circle {
			background: transparent;
			color: var(--add-btn-color, var(--theme-primary));
			border: 2px solid var(--add-btn-color, var(--theme-primary));
		}
	}
}
.btn-white {
	background-color: #fff;
	color: #3076bb;
	border: 1px solid #3076bb;
	font-weight: 700;
	border-radius: 20px;
	font-size: 14px;
	line-height: 18px;
	padding: 10px 20px;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:focus,
	&:active {
		background-color: #fff;
		border: 1px solid #3076bb;
		color: #3076bb;
	}
}
.location-btn {
	background-color: var(--forest-green);
	color: #ffffff;
	border: 1px solid var(--forest-green);
	font-weight: 400;
	border-radius: 20px;
	font-size: 14px;
	line-height: 18px;
	padding: 8px 12px;
	height: auto;
	display: flex;
	align-items: center;

	.location-circle {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		display: inline-flex;
		background: #fff;
		outline: 3px solid rgba(255, 255, 255, 0.6);
		margin-right: 7px;
		margin-bottom: 0.5px;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: var(--forest-green);
		border: 1px solid var(--forest-green);
		color: #ffffff;
	}
}
.btn-gradient {
	background: linear-gradient(
		90deg,
		rgba(48, 118, 187, 1) 0%,
		rgba(238, 59, 64, 1) 100%
	);
	border: 0;
	color: #fff;
	height: auto;
	font-size: 16px;
	line-height: 16px;
	padding: 12px 20px 10px;
	&:hover,
	&:active,
	&:focus {
		background: linear-gradient(
			90deg,
			rgba(48, 118, 187, 1) 0%,
			rgba(238, 59, 64, 1) 100%
		);
		border: 0;
		color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	}
}
.btn-hotJobs {
	color: var(--tan-hide);
	border-color: var(--tan-hide);

	&:hover,
	&:focus {
		color: var(--tan-hide);
		border-color: var(--tan-hide);
	}
}

.pb-container {
	display: inline-block;
	text-align: center;
	width: 100%;
	height: 42px;
}
.pb-container .pb-button {
	background: var(--theme-primary);
	border: 2px solid var(--theme-primary);
	border-radius: 26px;
	color: #ffffff;
	cursor: pointer;
	padding: 8px 16px;
	text-decoration: none;
	text-align: center;
	height: 42px;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	transition:
		background-color 0.3s,
		width 0.3s,
		border-width 0.3s,
		border-color 0.3s,
		border-radius 0.3s;
	&:hover,
	&:focus {
		border-color: var(--theme-primary-highlight);
		background-color: var(--theme-primary-highlight);
	}
}
.pb-container .pb-button span {
	display: block;
	transition: opacity 0.3s 0.1s;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}
.pb-container .pb-button svg {
	height: 42px;
	width: 42px;
	position: absolute;
	transform: translate(-50%, -50%);
	pointer-events: none;
}
.pb-container.loading .pb-button svg {
	left: -6px;
	top: -6px;
}
.pb-container .pb-button svg path {
	opacity: 0;
	fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
	animation: spin 0.9s infinite cubic-bezier(0.085, 0.26, 0.935, 0.71);
}
.pb-container .pb-button svg.pb-progress-circle path {
	stroke: var(--theme-primary);
	stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
	stroke: #ffffff;
	stroke-linecap: round;
	stroke-width: 4;
}
.pb-container.disabled .pb-button {
	cursor: not-allowed;
}
.pb-container.loading .pb-button {
	width: 42px !important;
	border-width: 6.5px;
	border-color: #ddd;
	cursor: wait;
	background-color: transparent;
	padding: 0;
	position: relative;
}
.pb-container.loading .pb-button span {
	transition: all 0.15s;
	opacity: 0;
	display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
	transition: opacity 0.15s 0.3s;
	opacity: 1;
}
.pb-container.success .pb-button {
	border-color: #a0d468;
	background-color: #a0d468;
}
.pb-container.success .pb-button span {
	transition: all 0.15s;
	opacity: 0;
	display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
	opacity: 1;
}
.pb-container.error .pb-button {
	border-color: #ed5565;
	background-color: #ed5565;
}
.pb-container.error .pb-button span {
	transition: all 0.15s;
	opacity: 0;
	display: none;
}
.pb-container.error .pb-button .pb-cross > path {
	opacity: 1;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
		transform-origin: center center;
	}
	to {
		transform: rotate(360deg);
		transform-origin: center center;
	}
}

.ant-result-extra > *:last-child {
	margin-right: 0;
}
