@font-face {
	font-family: 'icomoon';
	src: url('../../fonts/icomoon.eot?fhgbae');
	src:
		url('../../fonts/icomoon.eot?fhgbae#iefix')
			format('embedded-opentype'),
		url('../../fonts/icomoon.ttf?fhgbae') format('truetype'),
		url('../../fonts/icomoon.woff?fhgbae') format('woff'),
		url('../../fonts/icomoon.svg?fhgbae#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$icons: (
	user-two: '\e900',
	heart: '\e901',
	mail: '\e902',
	bell: '\e903',
	bin: '\e904',
	check: '\e905',
	share: '\e906',
	folder: '\e907',
	speedometer: '\e908',
	edit: '\e909',
	arrow-up: '\e90a',
	arrow-down: '\e90b',
	application: '\e90c',
	arrow-left: '\e90d',
	cross: '\e90e',
	fire: '\e90f',
	location: '\e910',
	group: '\e911',
	leaf: '\e912',
	message: '\e913',
	export: '\e914',
	referral: '\e915',
	search: '\e916',
	settings: '\e917',
	speaker: '\e918',
	download: '\e919',
	plus: '\e91a',
	phone: '\e91b',
	building: '\e91c',
	message-fill: '\e91d',
	telephone-outline: '\e91e',
	message-outline: '\e91f',
	envelope-outline: '\e920',
);

@each $name, $icon in $icons {
	.icon-#{$name}:before {
		content: $icon;
	}
}

.custom-card-icon {
	width: 55px;
	height: 55px;
}
