:root {
	--theme-primary: #0686d0;
	--theme-primary-highlight: #24a4ee;
	--flamingo: #ef3c3f;
	--lochmara: #0686d0;
	--black: #000000;
	--dove-gray: #666666;
	--oslo-gray: #95979a;
	--cello: #21365d;
	--forest-green: #1d8627;
	--fern: #6cb761;
	--sunset-orange: #ff4d4f;
	--tan-hide: #f7a34d;
	--bright-turquoise: #14d6d3;
	--deep-blush: #e75da7;
	--royal-blue: #7942e7;
	--silver-chalice: #b1b1b1;
	--mellow-yellow: #f3cd5d;
	--old-mandarin: #8e2123;
}
