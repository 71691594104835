.reward-modal {
	.badges-list {
		display: flex;
		flex-wrap: wrap;
		.badge-container {
			// flex-grow: 1;
			width: 33%;
		}
	}
	.ant-modal-body {
		height: 410px;
		overflow-y: auto;
	}
}

.home-my-reward {
	border: 1px solid #fff;
	transition: box-shadow 0.3s;
	padding: 10px 10px;
	&.single-reward {
		svg {
			width: 122px;
		}
	}
	// &:hover{
	// 	border-radius:10px;
	// 	border: 1px solid #ccc;
	// 	box-shadow: 0 0 11px rgba(33,33,33,.2);
	// }
}

.your-rewards {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	// justify-content: center;
	&.your-rewards-list {
		overflow-y: scroll;
		.home-my-reward {
			padding: 10px 0;
		}
	}
}
